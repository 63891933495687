import {AES, enc} from "crypto-js";

export const encryptDataToString = (value: string) => {
  return AES.encrypt(value, process.env.NEXT_PUBLIC_CRYPTO_SALT || "").toString();
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const decryptDataToString = <T extends unknown>(
  value: string,
  parseAsJson = false
): T | null => {
  try {
    const bytes = AES.decrypt(value, process.env.NEXT_PUBLIC_CRYPTO_SALT || "");
    return parseAsJson ? JSON.parse(bytes.toString(enc.Utf8)) : bytes.toString(enc.Utf8);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return null;
  }
};
