import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconFlip: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 12"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M9.18 7.952V5.405a1880.27 1880.27 0 0 0 3.225 3.303l-3.22 3.29V9.3c-.268-.019-.52-.035-.772-.059-1.917-.166-3.794-.501-5.567-1.31-.764-.35-1.476-.778-2.053-1.41C.42 6.11.14 5.645.042 5.087c-.126-.7.055-1.327.449-1.898.451-.654 1.071-1.107 1.751-1.485C3.253 1.144 4.335.79 5.452.527A18.845 18.845 0 0 1 9.2.018a34.62 34.62 0 0 1 2.742.006c1.969.102 3.9.432 5.745 1.172.77.308 1.497.691 2.135 1.238.373.32.693.684.909 1.132.451.935.323 1.817-.273 2.64-.473.655-1.116 1.1-1.812 1.472-.972.52-2.007.853-3.07 1.102-.344.08-.69.153-1.037.228l-.097.013V7.676c.362-.089.722-.172 1.081-.263.933-.236 1.841-.539 2.684-1.016.447-.252.864-.547 1.182-.965.407-.537.396-1.06-.029-1.585-.41-.501-.943-.834-1.507-1.115-1.009-.504-2.08-.807-3.178-1.027a19.972 19.972 0 0 0-2.613-.34 23.48 23.48 0 0 0-2.82-.016c-.974.053-1.943.163-2.902.356-1.208.241-2.39.58-3.48 1.182-.472.263-.913.566-1.246 1.006-.4.523-.394 1.045.015 1.563.405.51.938.839 1.502 1.123 1.011.51 2.09.807 3.19 1.022a22.58 22.58 0 0 0 2.64.34c.06.016.131.01.218.01z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h21v12H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconFlip;
