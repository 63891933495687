import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconBall: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M11.93 24C5.227 24 0 18.667 0 11.84 0 5.354 5.53-.061 12.111 0 18.671.06 24.081 5.495 24 12.06c-.08 6.95-5.672 12.06-12.07 11.94zm4.299-15.252c-1.877.242-3.654.404-5.39.727-3.451.627-6.6 1.94-9.164 4.445-.403.383-.545.727-.282 1.212.706 1.313 1.332 2.687 2.12 3.94.18.302.887.504 1.27.424 2.262-.506 4.18-1.637 5.733-3.334 1.716-1.899 3.089-4.222 5.915-4.707-.06-.95-.122-1.737-.202-2.707zm-.303-.869c-.121-1.293-.565-1.96-1.877-2.06-1.171-.081-2.322-.586-3.492-.788-1.211-.202-2.463-.485-3.654-.384-2.059.162-3.835 1.212-4.925 2.91-1.01 1.575-1.13 3.514-.888 5.575 4.219-4 9.467-4.869 14.836-5.253zm.525 4.627c-.222.02-.343.02-.465.04-1.453.424-2.422 1.495-3.35 2.626-1.595 1.96-3.35 3.738-5.773 4.667-.606.222-1.211.404-2.019.687 2.16 1.252 3.957 2.889 6.52 2.02.747-.263 1.554-.546 2.1-1.05 2.563-2.425 2.825-5.657 2.987-8.99zM13.342 22.99c2.483-.383 4.42-1.313 6.056-2.808.363-.323.747-.747.868-1.192.484-1.697.383-3.393-.444-4.97-.485-.929-1.211-1.555-2.402-1.535-.182 3.9-.666 7.637-4.078 10.505zm8.175-5.474c1.353-2.344 1.655-4.566 1.433-6.89-.02-.201-.222-.423-.403-.545-1.635-1.01-3.452-1.212-5.35-1.232.081.91.162 1.717.243 2.525 3.37.546 3.916 3.07 4.077 6.142zM4.24 4.304c2.684-1.213 5.288-.768 7.912.202.788.282 1.676.363 2.745.606-1.19-2.182-2.724-3.536-4.945-3.677C7.57 1.273 5.874 2.748 4.24 4.304zm18.247 4.383c-.525-1.798-1.494-3.333-2.987-4.686-.525 1.454-1.797 1.737-2.988 2.06.424 1.758.444 1.737 2.039 1.94 1.272.181 2.543.444 3.936.686zM12.696.99c.929 1.03 1.918 1.96 2.725 3.03.828 1.092 1.736 1.273 2.725.284.626-.627.606-1.071-.12-1.536-1.656-1.05-3.452-1.656-5.33-1.778z"
      fill="#C2C2C2"
    />
  </Icon>
);

export default IconBall;
