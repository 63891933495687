import * as icons from "@ef-org/icons";

import * as React from "react";

import {IconProps} from "@chakra-ui/react";

const ICONS = {
  Arrow: icons.IconArrow,
  Ball: icons.IconBall,
  Check: icons.IconCheck,
  Flip: icons.IconFlip,
  ICharity: icons.IconICharity,
  IConference: icons.IconIConference,
  ICorporate: icons.IconICorporate,
  IFoodAndDrink: icons.IconIFoodAndDrink,
  IMusic: icons.IconIMusic,
  IOnline: icons.IconIOnline,
  ISports: icons.IconISports,
  ITradeShow: icons.IconITradeShow,
  Logo: icons.IconLogo,
  Maximize: icons.IconMaximize,
  Star: icons.IconStar,
  User: icons.IconUser,
};

export type IconType = keyof typeof ICONS;

const Icon: React.FC<
  IconProps & {id: IconType; fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({id, ...props}) => {
  if (!id) {
    return null;
  }

  const Component = ICONS[id];
  return <Component {...props} />;
};

export default Icon;
