/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import {omit, pick} from "ramda";

export const CHAKRA_SPACING = [
  "m",
  "my",
  "mx",
  "mr",
  "mt",
  "mb",
  "ml",
  "p",
  "py",
  "px",
  "pr",
  "pl",
  "pt",
  "pb",
  "w",
  "width",
];

export const resolveChakraSpacing = <
  Type extends unknown,
  SpacingReturn extends unknown,
  RestReturn extends unknown
>(
  props: Type
): {spacing: SpacingReturn; rest: RestReturn} => ({
  spacing: (pick(CHAKRA_SPACING, props) || {}) as SpacingReturn,
  rest: (omit(CHAKRA_SPACING, props) || {}) as RestReturn,
});
