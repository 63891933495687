import {COOKIES_EVENTS_BUFFER, COOKIES_LAST_PAGE_URL} from "@ef-org/constants";
import mixpanel, {Dict} from "mixpanel-browser";
import {isEmpty} from "ramda";

type EVENTS =
  | "ACCOUNT:SIGN_IN"
  | "ACCOUNT:SIGN_UP"
  | "ACCOUNT:RESET_PASSWORD"
  | "EVENT:VISITED"
  | "PAGE:VISITED"
  | "ORDER:SUBMITTED"
  | "ORDER:UNSUCCESSFUL"
  | "ORDER:SUCCESS_CHECKOUT_VIEW_ORDER"
  | "ORDER:SUCCESS_CHECKOUT_BACK_TO_EVENT"
  | "ORDER:SUCCESSFUL"
  | "NFT:WITHDRAWAL:MODAL_OPENED"
  | "NFT:WITHDRAWAL:WALLET_ID_ADDED"
  | "NFT:WITHDRAWAL:INITIALIZATION_ERROR"
  | "NFT:WITHDRAWAL:INITIALIZATION_SUCCESSFUL"
  | "NFT:WITHDRAWAL:EMAIL_CONFIRMATION_ERROR"
  | "NFT:WITHDRAWAL:EMAIL_CONFIRMATION_SUCCESSFUL";

type Item = {eventName: EVENTS; payload?: Dict};

const skipReporting = ["local", "stage"].includes(process.env.NEXT_PUBLIC_ENV_TYPE);

const _trackEvent = (eventName: EVENTS, payload: Dict = {}) => {
  if (eventName === "PAGE:VISITED") {
    window[COOKIES_LAST_PAGE_URL] = payload.pathname;
  }

  mixpanel.track(eventName, payload);
};

const _emptyEventsBuffer = () => {
  if (!isEmpty(window[COOKIES_EVENTS_BUFFER] || [])) {
    window[COOKIES_EVENTS_BUFFER].forEach((x) => {
      _trackEvent(x.eventName, x.payload);
    });
    window[COOKIES_EVENTS_BUFFER] = [];
  }
};
const saveToBuffer = (item: Item) => {
  if (!window[COOKIES_EVENTS_BUFFER]) {
    window[COOKIES_EVENTS_BUFFER] = [];
  }
  window[COOKIES_EVENTS_BUFFER].push(item);
};

export const mixpanelTrackEvent = (eventName: EVENTS, properties?: Dict) => {
  // if (skipReporting) return;
  // const currentUser = sessionStorage ? sessionStorage.getItem(COOKIES_USER_ID) : null;
  // const parsedData = currentUser
  //   ? decryptDataToString<{uid?: string; email?: string}>(currentUser, true)
  //   : null;
  // const payload = {
  //   ...(parsedData && {
  //     userId: parsedData?.uid,
  //     email: parsedData?.email,
  //     isInternalAccount: parsedData?.email.includes("@eventsframe.com"),
  //   }),
  //   env: process.env.NODE_ENV,
  //   ...properties,
  // };
  // if (false) {
  //   _emptyEventsBuffer();
  //   _trackEvent(eventName, payload);
  // } else {
  //   saveToBuffer({payload, eventName});
  // }
};

export const mixpanelIdentifyUser = (userId: string, props: {email: string}) => {
  if (skipReporting) return;

  mixpanel.identify(userId);

  if (props) {
    mixpanel.people.set(props);
  }
};

export const mixpanelInit = () => {
  // eslint-disable-next-line no-console
  console.log("MIXPANEL SKIPPED", skipReporting);

  if (skipReporting) return;
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY, {
    debug: true,
  });

  // window.isTrackingReady = true;

  _emptyEventsBuffer();
};
