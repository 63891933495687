import * as React from "react";
import {FieldError} from "react-hook-form";

import {
  Text,
  Select as CSelect,
  Stack,
  Box,
  useStyleConfig,
  SelectProps as ChakraSelectProps,
  Collapse,
} from "@chakra-ui/react";

export type SelectProps = ChakraSelectProps & {
  onSelectValue?: (value: string) => void;
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  error?: FieldError;
  required?: boolean;
  dataTest?: string;
  customError?: string;
  customMessage?: React.ReactNode;
  customBottomElement?: React.ReactNode;
  labelColor?: string;
  options?: Array<{label?: string; value: string}>;
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      onSelectValue,
      options = [],
      label,
      labelRight,
      error,
      required,
      customMessage,
      customBottomElement,
      dataTest,
      customError,
      labelColor,
      placeholder,
      variant,
      ...props
    },
    ref
  ) => {
    const containerRef = React.useRef(null);
    const chakraThemeStyles = useStyleConfig("ObSelect", {variant});

    const [isFocus, setIsFocus] = React.useState(false);
    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    return (
      <Box w="100%">
        {(label || labelRight) && (
          <Stack
            direction="row"
            w="100%"
            align="center"
            justify={label && labelRight ? "space-between" : labelRight ? "flex-end" : "flex-start"}
          >
            {label && React.isValidElement(label) ? (
              label
            ) : (
              <Text
                variant="form-label"
                color={Boolean(error) ? "ef-red" : labelColor ? labelColor : "ef-black"}
                size="medium"
              >
                {label}
                {(required || props.isRequired) && (
                  <Text as="span" pl="3px" color="ef-red">
                    *
                  </Text>
                )}
              </Text>
            )}

            {labelRight && React.isValidElement(labelRight) ? (
              labelRight
            ) : (
              <Text variant="form-label" color="ef-black" size="small">
                {labelRight}
              </Text>
            )}
          </Stack>
        )}
        <Stack position="relative">
          <CSelect
            ref={ref}
            h={props?.h || props?.size === "lg" ? "52px" : props?.size === "md" ? "40px" : "52px"}
            sx={{...chakraThemeStyles, border: "0"}}
            {...props}
            bg="#F8F8F8"
            onFocus={(e) => {
              props.onFocus && props.onFocus(e);
              setIsFocus(true);
            }}
            onBlur={(e) => {
              props.onBlur && props.onBlur(e);
              setTimeout(() => {
                setIsFocus(false);
              }, 100);
            }}
            icon={
              <Box mr="20px" transform={isFocus ? "rotateX(180deg)" : null} transition="all 0.3s">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5196 16.1233C11.5731 16.2011 11.6447 16.2646 11.7282 16.3085C11.8118 16.3524 11.9047 16.3754 11.9991 16.3754C12.0934 16.3754 12.1864 16.3524 12.2699 16.3085C12.3535 16.2646 12.4251 16.2011 12.4786 16.1233L17.7286 8.54001C17.7893 8.45254 17.825 8.35009 17.8316 8.24379C17.8382 8.13749 17.8156 8.03141 17.7662 7.93707C17.7168 7.84273 17.6424 7.76373 17.5513 7.70867C17.4601 7.65361 17.3556 7.62459 17.2491 7.62476H6.74908C6.64282 7.6252 6.53869 7.65459 6.44789 7.70978C6.35709 7.76498 6.28305 7.84388 6.23374 7.938C6.18443 8.03213 6.16171 8.13791 6.16803 8.24398C6.17434 8.35005 6.20945 8.4524 6.26958 8.54001L11.5196 16.1233Z"
                    fill={isFocus ? "#1F2FBA" : "#727CD4"}
                  />
                </svg>
              </Box>
            }
          />
          <Text
            position="absolute"
            top={props?.size === "lg" ? "15px" : props?.size === "md" ? "2px" : "6px"}
            left="20px"
            color={isFocus || value ? "ob-black" : "ob-grey-300"}
            onClick={() => {
              setIsFocus(true);
            }}
          >
            {value ? value : placeholder}
          </Text>
          {isFocus && (
            <Stack
              spacing="0"
              borderWidth="1px"
              borderColor="ob-blue"
              borderRadius="15px"
              bg="#F8F8F8"
              zIndex="999"
              top={props?.size === "lg" ? "46px" : props?.size === "md" ? "35px" : "46px"}
              position="absolute"
              id="container"
              ref={containerRef}
              w="100%"
              align="center"
            >
              {options?.map(({label, value}, i) => (
                <Box
                  key={i}
                  w="100%"
                  py="20px"
                  px="16px"
                  _hover={{bg: "ob-blue-100"}}
                  cursor="pointer"
                  role="group"
                  borderTopRadius={i === 0 ? "15px" : "unset"}
                  borderBottomRadius={i === options.length - 1 ? "15px" : "unset"}
                  onClick={() => {
                    setValue(value);
                    onSelectValue && onSelectValue(value);
                  }}
                >
                  <Text
                    lineHeight="19px"
                    // as="option"
                    _groupHover={{color: "ob-blue"}}
                  >
                    {label ? label : value}
                  </Text>
                </Box>
              ))}
            </Stack>
          )}
        </Stack>
        <Collapse in={Boolean(error?.message)} animateOpacity>
          {(error?.message || customError) && (
            <Stack direction="row" align="center" w="fit-content" pt="4px">
              <Box h="16px" w="3px" bg="ef-red" />
              <Text variant="form-error">{customError ? customError : error.message}</Text>
            </Stack>
          )}
        </Collapse>

        {(customMessage || customBottomElement) && (
          <Box mt={Boolean(error?.message) ? "0.5rem" : "0"}>
            {customMessage ? (
              <Text variant="form-label" size="small" color="ef-gray">
                {customMessage}
              </Text>
            ) : (
              customBottomElement
            )}
          </Box>
        )}
      </Box>
    );
  }
);

Select.displayName = "Select";

export default Select;
