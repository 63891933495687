import {ConditionalRule, NFTCanvas} from "@ef-org/components";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";

import React, {useState} from "react";

import {Text, Button, Heading, Stack, Image, useImage, AspectRatio} from "@chakra-ui/react";

import Skeleton from "../components/Skeleton";

type NFTPublishedModalProps = {
  onClose?: () => void;
  nftUrl: string;
  nftName: string;
  nftPreview?: string;
};

const NFTPublishedModal: React.FC<NFTPublishedModalProps> = ({
  onClose,
  nftUrl,
  nftName,
  nftPreview,
}) => {
  const {closeModal} = useModal("useNFTPublishedModal");
  const [count, setCount] = useState(0);

  const status = useImage({src: `${nftUrl}?nocache=${count}`});

  React.useEffect(() => {
    if (status !== "failed" || nftPreview) {
      return;
    }

    setTimeout(() => {
      setCount((x) => x + 1);
    }, 1000);
  }, [status]);

  const closeWrapper = () => {
    closeModal();
    onClose && onClose();
  };

  return (
    <Stack p={["2rem", "2rem", "2rem 3rem"]} align="center">
      <Heading variant="h3" pb="1rem" textAlign="center">
        Congrats! Your NFT is published
      </Heading>

      <ConditionalRule
        when={Boolean(nftPreview)}
        whenTrueComponent={
          <AspectRatio w="100%" ratio={1}>
            <NFTCanvas nftAssetUrl={nftPreview} />
          </AspectRatio>
        }
        whenFalseComponent={
          <>
            {status === "loaded" ? (
              <Image src={nftUrl} pb="1rem" w="100%" h="auto" alt="final-nft" />
            ) : (
              <Skeleton w="100%" h="265px" pb="1rem" />
            )}
          </>
        }
      />

      <Text pb="1rem">
        Your NFT <b>{nftName}</b> is now published.
      </Text>

      <Stack w="100%" direction="row" justify="flex-end">
        <Button variant="blue" onClick={closeWrapper}>
          OK
        </Button>
      </Stack>
    </Stack>
  );
};

export const useNFTPublishedModal: InitialUseCreateModalType<NFTPublishedModalProps> = () =>
  useCreateModal("useNFTPublishedModal", NFTPublishedModal, {
    size: "lg",
    hideCloseButton: true,
    closeOnEsc: false,
    closeOnOverlayClick: false,
  });
