import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconInfo: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    cursor="pointer"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M14.0002 27.333C6.63616 27.333 0.666829 21.3623 0.666829 13.9997C0.66683 6.637 6.63616 0.666338 14.0002 0.666339C21.3642 0.666339 27.3335 6.63701 27.3335 13.9997C27.3335 21.3623 21.3642 27.333 14.0002 27.333ZM14.0002 25.1103C20.1268 25.1103 25.1108 20.1263 25.1108 13.9997C25.1108 7.87301 20.1268 2.88901 14.0002 2.88901C7.8735 2.88901 2.8895 7.873 2.8895 13.9997C2.8895 20.1263 7.8735 25.1103 14.0002 25.1103ZM14.0019 10.6634C13.2666 10.6634 12.6704 10.0671 12.6704 9.3318C12.6704 8.59647 13.2666 8.00021 14.0019 8.00021C14.7374 8.00021 15.3335 8.59647 15.3335 9.33181C15.3335 10.0671 14.7374 10.6634 14.0019 10.6634ZM14.0075 20.6663C13.5012 20.6667 13.0826 20.2909 13.016 19.8028L13.0067 19.6671L13.0019 13.665C13.0015 13.1127 13.4488 12.6646 14.0011 12.6642C14.5074 12.6638 14.9262 13.0397 14.9927 13.5277L15.0019 13.6634L15.0067 19.6655C15.0072 20.2178 14.5599 20.6659 14.0075 20.6663Z"
      fill="#1F2FBA"
    />
  </Icon>
);

export default IconInfo;
