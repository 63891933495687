/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {isEmpty} from "ramda";

import * as React from "react";

const useClickOutside = (
  ref: React.RefObject<HTMLElement> | null | undefined,
  handler: (ev: React.SyntheticEvent<HTMLLinkElement>) => void,
  allowedRefs: Array<React.RefObject<HTMLElement> | null | undefined> = []
): void => {
  React.useEffect(() => {
    const handleClose = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (isEmpty(allowedRefs)) {
          return handler(event);
        }

        if (!allowedRefs.some((x) => x?.current?.contains(event.target))) {
          return handler(event);
        }
      }
    };
    window.addEventListener("mousedown", handleClose);
    window.addEventListener("touchstart", handleClose);
    return () => {
      window.removeEventListener("mousedown", handleClose);
      window.removeEventListener("touchstart", handleClose);
    };
  }, [handler, ref]);
};

export default useClickOutside;
