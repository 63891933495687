import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconICorporate: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 21"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      d="M6.227 2.199H4.828a.99.99 0 0 0-.994.994v.773h-1.73C1.515 3.966 1 4.444 1 5.07V19.02c0 .59.479 1.104 1.104 1.104h21.792c.589 0 1.104-.478 1.104-1.104V5.07c0-.59-.479-1.104-1.104-1.104h-1.73v-.773a.99.99 0 0 0-.994-.994h-1.4a.99.99 0 0 0-.993.994v.773h-2.024V2.75c0-1.141-.92-2.062-2.062-2.062h-3.35c-1.14 0-2.06.92-2.06 2.062l-.001 1.215H7.258v-.773c-.037-.553-.48-.994-1.031-.994zm-1.656.994c0-.148.11-.258.257-.258h1.4c.146 0 .257.11.257.258v.773H4.57v-.773zM1.736 19.02V5.07c0-.184.147-.369.368-.369H5.16V19.39H2.104a.369.369 0 0 1-.368-.368zM23.896 4.7c.184 0 .368.148.368.369v13.951a.369.369 0 0 1-.368.368H20.84V4.702h3.056zm-4.38-1.508c0-.148.11-.258.257-.258h1.399c.147 0 .258.11.258.258v.773h-1.915v-.773zM9.98 2.75c0-.736.59-1.325 1.326-1.325h3.35a1.32 1.32 0 0 1 1.325 1.325v1.215h-6V2.75zM20.104 4.7V19.39H5.896V4.702h14.208z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M13.257 14.789a1.32 1.32 0 0 1-1.325-1.326c0-.22-.147-.368-.368-.368-.22 0-.368.147-.368.368 0 .957.7 1.804 1.583 1.988v.92c0 .221.147.369.368.369.22 0 .368-.148.368-.369v-.883c.994-.147 1.767-.994 1.767-2.025 0-1.14-.92-2.06-2.061-2.06a1.32 1.32 0 0 1-1.325-1.326c0-.7.588-1.288 1.288-1.288h.074c.699 0 1.288.589 1.288 1.288 0 .22.147.368.368.368.22 0 .368-.147.368-.368 0-.994-.736-1.84-1.693-2.025V7.17c0-.22-.147-.368-.368-.368s-.369.147-.369.368v.883c-.957.184-1.693.994-1.693 2.025 0 1.141.92 2.061 2.061 2.061.737 0 1.326.59 1.326 1.325 0 .737-.59 1.326-1.289 1.326z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconICorporate;
