import React from "react";

import {Box, BoxProps, Skeleton as ChakraSkeleton} from "@chakra-ui/react";

const Skeleton: React.FC<{asText?: boolean} & BoxProps> = ({asText, ...props}) => {
  return (
    <Box position="relative" w="100%" h="40px" {...props} borderRadius="6px">
      <Box
        w="100%"
        h="100%"
        position="absolute"
        bgGradient={
          asText
            ? "linear(to-r, #FFFFFF, transparent)"
            : "linear-gradient(30deg, #FFFFFF 14.01%, #FFFFFF 40%, transparent)"
        }
        zIndex="999"
        borderRadius="inherit"
      />
      <ChakraSkeleton
        w="inherit"
        h="inherit"
        position="absolute"
        zIndex="998"
        borderRadius="inherit"
        endColor="gray.400"
      />
    </Box>
  );
};

export default Skeleton;
