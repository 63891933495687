//@ts-nocheck

export const dataCys = {
  components: {
    navbar: {signOutBtn: "navbar:signout:btn"},
    walletSideBar: {signOut: "walletSideBar:signOut"},
  },
  eventDetail: {
    heading: "eventDetail:heading",
    nftPreviewHeader: "eventDetail:nftPreviewHeader",
    ticketsSection: "eventDetail:ticketsSection",
    ticketRowPrice: (ticketSlug) => `eventDetail:ticketRow:id:${ticketSlug}:price`,
    ticketRowSelectBox: (ticketSlug) => `eventDetail:ticketRow:id:${ticketSlug}:selectBox`,

    merchSection: "eventDetail:merch",
    merchItemPrice: (merchSlug) => `eventDetail:merch:id:${merchSlug}:price`,

    checkoutBtn: "eventDetail:checkout:btn",
    checkoutTotalPrice: "eventDetail:checkout:totalPrice",
  },
  eventCheckout: {
    myOrderHeading: "checkout:myOrder:heading",
    totalPrice: "checkout:totalPrice",
    promoCodeSection: "checkout:promoCodeSection",
    promoCodeInput: "checkout:promoCodeInput",
    promoCodeBtnApply: "checkout:promoCodeBtnApply",
    promoCodeBtnDelete: "checkout:promoCodeBtnDelete",
    formEmail: "checkout:form:email",
    formEmailSingInBtn: "checkout:form:email:signin",
    formFirstName: "checkout:form:firstName",
    formLastName: "checkout:form:lastName",
    formCountry: "checkout:form:country",
    formAddCompanyInfoCheckbox: "checkout:form:addCompanyInfo:checkbox",
    formBillingAddress: "checkout:form:billingAddress",
    formBillingAddressTwo: "checkout:form:billingAddressTwo",
    formBillingCity: "checkout:form:billingCity",
    formPostalCode: "checkout:form:postalCode",
    formBillingState: "checkout:form:billingState",
    formOrganization: "checkout:form:organization",
    formOrganizationVatId: "checkout:form:organizationVatId",
    formTerms: "checkout:form:terms",
    attendeeAddNow: "checkout:form:attendeeAddNow",
    attendeeAddLater: "checkout:form:attendeeAddLater",
    attendeeErrorMsg: "checkout:form:attendeeErrorMsg",
    paymentStripeBtn: "checkout:form:paymentStripeBtn",
    paymentPaypalBtn: "checkout:form:paymentPaypalBtn",
    paymentOfflineBtn: "checkout:form:paymentOfflineBtn",
    paymentChangeGateBtn: "checkout:form:paymentChangeGateBtn",
    paymentSubmitOrderBtn: "checkout:form:paymentsSubmitOrderBtn",
    attdUnassignedTicketsText: "checkout:form:attd:unassignedLabel",
    attdAddNewBtn: "checkout:form:attd:addNewBtn",
    attdEditBtn: (id) => `checkout:form:attd:${id}:edit`,
    attdDeleteBtn: (id) => `checkout:form:attd:${id}:delete`,
    attdNameLabel: (id) => `checkout:form:attd:${id}:name`,
  },
  myWallet: {
    heading: "myWallet:heading",
  },
  loginPage: {
    formEmail: "loginPage:form:email",
    formPassword: "loginPage:form:password",
    formSubmit: "loginPage:form:submit",
  },
  successOrderPage: {
    viewOrderBtn: "successOrderPage:viewOrderBtn",
    backtoEventPageBtn: "successOrderPage:backtoEventPageBtn",
  },
  orderDetail: {
    myOrderHeading: "orderDetail:myOrderHeading",
    nftSection: "orderDetail:nftSection",
  },
  modals: {
    settings: {
      closeBtn: "modal:close",
    },
    loginSignInForm: {
      email: "modal:loginSignInForm:email",
      password: "modal:loginSignInForm:password",
      btn: "modal:loginSignInForm:btn",
    },
    checkoutAttendees: {
      email: "modal:checkout:email",
      label: "modal:checkout:label",
      ticket: (id) => `modal:checkout:${id}:checkbox`,
      saveBtn: "modal:checkout:save",
    },
  },
};
