import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconStar: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="m8 0 2.351 4.764 5.257.764-3.804 3.708.898 5.236L8 12l-4.702 2.472.898-5.236L.392 5.528l5.257-.764L8 0z"
      fill="#83E8C2"
    />
  </Icon>
);

export default IconStar;
