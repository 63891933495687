export const CURRENCIES = {
  aed: "__price__\u00A0د.إ",
  amd: "AMD\u00A0__price__",
  aud: "$\u00A0__price__",
  bhd: "__price__ BD",
  brl: "R$__price__",
  byn: "Br__price__",
  cad: "$__price__",
  chf: "SFr.\u00A0__price__",
  clp: "$__price__",
  cny: "¥__price__",
  cop: "$__price__",
  czk: "__price__\u00A0Kč",
  dkk: "__price__\u00A0kr",
  eur: "__price__\u00A0€",
  gbp: "£__price__",
  hkd: "HK$__price__",
  huf: "__price__\u00A0Ft",
  idr: "Rp\u00A0__price__",
  ils: "₪\u00A0__price__",
  inr: "__price__\u00A0₹",
  isk: "__price__\u00A0ISK",
  jod: "__price__\u00A0JD",
  jpy: "¥__price__",
  kgs: "__price__\u00A0som",
  krw: "₩__price__",
  kwd: "__price__\u00A0K.D.",
  kzt: "₸__price__",
  mxn: "$\u00A0__price__",
  myr: "RM__price__",
  nok: "kr\u00A0__price__",
  nzd: "$\u00A0__price__",
  omr: "ر.ع.\u00A0__price__",
  pen: "__price__\u00A0S/.",
  php: "₱\u00A0__price__",
  pln: "__price__\u00A0zł",
  qar: "QR\u00A0__price__",
  ron: "__price__\u00A0lei",
  rsd: "__price__\u00A0din.",
  rub: "__price__\u00A0р.",
  sar: "__price__\u00A0SR",
  sek: "__price__\u00A0kr",
  sgd: "$\u00A0__price__",
  thb: "฿\u00A0__price__",
  try: "__price__\u00A0TL",
  twd: "NT$__price__",
  uah: "__price__\u00A0грн.",
  usd: "$\u00A0__price__",
  uzs: "__price__\u00A0som",
  yer: " __price__\u00A0﷼",
  zar: "R\u00A0__price__",
};
