import {ModalProvider} from "@ef-org/modals";
import * as Sentry from "@sentry/react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

import React, {ReactElement, ReactNode} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Provider} from "react-redux";

import {NextPage} from "next";
import {NextSeo} from "next-seo";
import {AppProps} from "next/app";
import Head from "next/head";
import Script from "next/script";

import {ChakraProvider} from "@chakra-ui/react";

import Fonts from "../src/fonts";
import "../src/global.css";
import {store} from "../src/store";
import theme from "../src/theme";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({Component, pageProps}: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Head>

      <link href="https://cdn.quilljs.com/1.0.0/quill.snow.css" rel="stylesheet" />

      <Script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js" />

      {/* <Script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAORSiKPunf1xfMgGlOPDRz-I42txYiCv0&libraries=places"
        async
        defer
      /> */}

      <NextSeo
        title="Organiser | EventsFrame"
        titleTemplate="%s | Organiser | EventsFrame"
        canonical="https://organiser.eventsframe.com"
        openGraph={{
          title: "Organiser | EventsFrame",
          url: "https://organiser.eventsframe.com",
          description: "Organiser | EventsFrame",
          images: [
            {
              url: "https://uploads-ssl.webflow.com/625e843e32d684c692beb3ae/63165e33bfb65ade3394c359_features_landing_page.jpg",
              alt: "@EventsFrame",
            },
          ],
          locale: "cs_CZ",
          site_name: "EventsFrame",
          type: "website",
        }}
        // robotsProps={{noarchive: true}}
      />

      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ErrorBoundary fallbackRender={null}>
            {/* <AuthProvider> */}
            <Sentry.ErrorBoundary fallback={null}>
              <Elements stripe={stripePromise}>
                <ModalProvider>
                  <Fonts />
                  {getLayout(<Component {...pageProps} />)}
                  {/* <Component {...pageProps} /> */}
                </ModalProvider>
              </Elements>
              {/* </AuthProvider> */}
            </Sentry.ErrorBoundary>
          </ErrorBoundary>
        </ChakraProvider>
      </Provider>
    </>
  );
}

export default CustomApp;
