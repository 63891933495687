import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconITradeShow: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 25"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      d="M23.8.69H2.2A1.2 1.2 0 0 0 1 1.89v.8a1.2 1.2 0 0 0 .8 1.126v9.747A1.2 1.2 0 0 0 1 14.69v8.8a1.2 1.2 0 0 0 1.2 1.2h21.6a1.2 1.2 0 0 0 1.2-1.2v-8.8a1.2 1.2 0 0 0-.8-1.127V3.816A1.2 1.2 0 0 0 25 2.69v-.8a1.2 1.2 0 0 0-1.2-1.2zm-12 3.2v9.6h-.82a4.005 4.005 0 0 0-2.5-3.314 2.4 2.4 0 1 0-2.957 0A4.007 4.007 0 0 0 3.02 13.49H2.6v-9.6h9.2zm1.6 0v9.6h-.8v-9.6h.8zm10 0v9.6h-.42a4.004 4.004 0 0 0-2.5-3.314 2.4 2.4 0 1 0-2.957 0 4.007 4.007 0 0 0-2.502 3.314h-.82v-9.6h9.2zm-6 4.4a1.6 1.6 0 1 1 3.201 0 1.6 1.6 0 0 1-3.2 0zm4.773 5.2h-6.345a3.197 3.197 0 0 1 6.345 0zM5.4 8.29a1.6 1.6 0 1 1 3.201 0 1.6 1.6 0 0 1-3.2 0zm4.773 5.2H3.828a3.197 3.197 0 0 1 6.345 0zm-8.373 10v-8.8a.4.4 0 0 1 .4-.4h10.4v9.6H2.2a.4.4 0 0 1-.4-.4zm22.4 0a.4.4 0 0 1-.4.4H13.4v-9.6h10.4a.4.4 0 0 1 .4.4v8.8zm0-20.8a.4.4 0 0 1-.4.4H2.2a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4h21.6a.4.4 0 0 1 .4.4v.8z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M8.2 17.49a.4.4 0 1 0 .8 0 2.004 2.004 0 0 0-1.6-1.96v-.04a.4.4 0 1 0-.8 0v.04a2 2 0 0 0 0 3.92v2.367a1.2 1.2 0 0 1-.8-1.127.4.4 0 1 0-.8 0 2.004 2.004 0 0 0 1.6 1.96v.04a.4.4 0 1 0 .8 0v-.04a2 2 0 0 0 0-3.92v-2.367a1.2 1.2 0 0 1 .8 1.127zm-2.4 0a1.201 1.201 0 0 1 .8-1.127v2.253a1.199 1.199 0 0 1-.8-1.126zm2.4 3.2a1.2 1.2 0 0 1-.8 1.126v-2.252a1.2 1.2 0 0 1 .8 1.126zM20.2 17.49a.4.4 0 1 0 .8 0 2.004 2.004 0 0 0-1.6-1.96v-.04a.4.4 0 1 0-.8 0v.04a2 2 0 0 0 0 3.92v2.367a1.2 1.2 0 0 1-.8-1.127.4.4 0 1 0-.8 0 2.004 2.004 0 0 0 1.6 1.96v.04a.4.4 0 1 0 .8 0v-.04a2 2 0 0 0 0-3.92v-2.367a1.199 1.199 0 0 1 .8 1.127zm-2.4 0a1.201 1.201 0 0 1 .8-1.127v2.253a1.199 1.199 0 0 1-.8-1.126zm2.4 3.2a1.2 1.2 0 0 1-.8 1.126v-2.252a1.2 1.2 0 0 1 .8 1.126z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconITradeShow;
