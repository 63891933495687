import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const Caret: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 16"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M0.201834 14.747L6.53632 7.99996L0.201834 1.25292C0.0336383 1.07383 -0.0294368 0.825061 0.0126168 0.593808C0.0378471 0.454939 0.100913 0.322431 0.201834 0.214976C0.235472 0.179157 0.271728 0.147743 0.309931 0.120929C0.577413 -0.0671654 0.940901 -0.0358505 1.17637 0.214976L7.99814 7.48099C8.12732 7.61868 8.2 7.80531 8.2 7.99996C8.2 8.19461 8.12732 8.38134 7.99814 8.51903L1.17637 15.785C0.907264 16.0717 0.470942 16.0717 0.201834 15.785C-0.0672738 15.4984 -0.0672738 15.0337 0.201834 14.747Z"
      fill="#727CD4"
    />
  </Icon>
);

export default Caret;
