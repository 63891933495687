export function capitalizeFirstLetter(string: string) {
  if (!string) return "";

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const rewriteFontToHumanReadable = (fontName: string): string => {
  if (!fontName || typeof fontName !== "string") return "";

  return capitalizeFirstLetter(fontName.replace(/-/g, " "));
};

export const rewriteFontToSavingFormat = (fontName: string): string => {
  if (!fontName || typeof fontName !== "string") return "";

  return fontName.replace(/ /g, "-").toLocaleLowerCase();
};

export const useHideOnProd = () => {
  return window?.location?.hostname !== "app.eventsframe.com";
};
