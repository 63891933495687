import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import Cookies from "js-cookie";
import querystring from "querystring";

import {refreshSession} from "../../helpers/aws/auth";

const notAuthorizedEndpoints = ["createUserUsersUserIdPut", "verifyCaptchaUsersVerifyCaptchaPost"];

export const addAPIExtraHeaders = async (headers: Headers) => {
  let token = Cookies.get("accessToken");

  try {
    if (!token) {
      const refreshResult = await refreshSession();
      token = refreshResult.token;
    }
    headers.set("authorization", token);
    return headers;
  } catch (e) {
    window.location.href = "/login";
  }
};

const fetchBaseQueryInstance = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  // credentials: "include",
  prepareHeaders: (headers, {endpoint}) => {
    return notAuthorizedEndpoints.includes(endpoint) ? headers : addAPIExtraHeaders(headers);
  },
  paramsSerializer: (params) => {
    return querystring.stringify(params);
  },
});

export const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  return await fetchBaseQueryInstance(args, api, extraOptions);
};
