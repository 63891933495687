import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconCheck: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M6.85365 11.4684L6.85364 11.4684C6.6584 11.2731 6.6584 10.9565 6.85364 10.7613C7.04891 10.566 7.36551 10.566 7.56078 10.7613L9.14654 12.3471L9.5001 12.7006L9.85365 12.3471L13.9395 8.26125L13.9395 8.26118C13.9643 8.23636 13.9907 8.21503 14.0181 8.19693L14.0183 8.1968C14.2122 8.0688 14.4761 8.09081 14.6465 8.26124C14.8418 8.45651 14.8418 8.77313 14.6465 8.96837L9.85364 13.7613C9.65841 13.9565 9.34179 13.9565 9.14655 13.7613L6.85365 11.4684ZM20 10.9077C20 16.1544 15.7467 20.4077 10.5 20.4077C5.25329 20.4077 1 16.1544 1 10.9077C1 5.66101 5.25329 1.40771 10.5 1.40771C15.7467 1.40771 20 5.66101 20 10.9077ZM10.5 19.4077C15.1944 19.4077 19 15.6022 19 10.9077C19 6.21329 15.1944 2.40771 10.5 2.40771C5.80558 2.40771 2 6.21329 2 10.9077C2 15.6022 5.80558 19.4077 10.5 19.4077Z"
      fill="#727CD4"
      stroke="white"
    />
  </Icon>
);

export default IconCheck;
