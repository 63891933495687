/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import {omit, pick} from "ramda";

import * as React from "react";
import {FieldError} from "react-hook-form";

import {
  Box,
  Collapse,
  Input as ChakraInput,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Stack,
  Text,
  useStyleConfig,
} from "@chakra-ui/react";

const CHAKRA_SPACING = [
  "m",
  "my",
  "mx",
  "mr",
  "mt",
  "mb",
  "ml",
  "p",
  "py",
  "px",
  "pr",
  "pl",
  "pt",
  "pb",
  "w",
  "width",
];

const resolveChakraSpacing = <
  Type extends unknown,
  SpacingReturn extends unknown,
  RestReturn extends unknown
>(
  props: Type
): {spacing: SpacingReturn; rest: RestReturn} => ({
  spacing: (pick(CHAKRA_SPACING, props) || {}) as SpacingReturn,
  rest: (omit(CHAKRA_SPACING, props) || {}) as RestReturn,
});

export type InputTypes =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export type InputProps = Omit<ChakraInputProps, "type"> & {
  onBlurCustom?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  type?: InputTypes;
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  error?: FieldError;
  rightElement?: React.ReactNode;
  required?: boolean;
  dataTest?: string;
  customError?: string;
  customMessage?: React.ReactNode;
  customBottomElement?: React.ReactNode;
  rightElementProps?: InputElementProps;
  labelColor?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  noBorder?: boolean;
  bigFontSize?: boolean;
  showPlaceholderElement?: boolean;
  placeholderElement?: React.ReactNode;
  customStyleConfig?: string;
  hideError?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      hideError,
      type,
      rightElement,
      noBorder,
      required,
      customMessage,
      dataTest,
      customError,
      rightElementProps,
      labelColor,
      leftContent,
      customBottomElement,
      labelRight,
      bigFontSize,
      showPlaceholderElement,
      placeholderElement,
      customStyleConfig,
      ...props
    },
    ref
  ) => {
    const inputStyles = useStyleConfig(customStyleConfig || "ObInput", {});
    const [showPassword, setShowPassword] = React.useState(false);

    const newProps = resolveChakraSpacing<InputProps, InputProps, InputProps>(props);
    const [isFocus, setIsFocus] = React.useState(false);

    const showRightContent = rightElement || type === "password";

    return (
      <Box w="100%" {...newProps.spacing} maxW={props.maxW}>
        {(label || labelRight) && (
          <Stack
            direction="row"
            w="100%"
            pb="3px"
            align="center"
            justify={label && labelRight ? "space-between" : labelRight ? "flex-end" : "flex-start"}
          >
            {label && React.isValidElement(label) ? (
              label
            ) : (
              <Text
                variant="form-label"
                color={Boolean(error) ? "ef-red" : labelColor ? labelColor : "ef-black"}
                size="medium"
              >
                {label}
                {(required || props.isRequired) && (
                  <Text as="span" pl="3px" color="ef-red">
                    *
                  </Text>
                )}
              </Text>
            )}

            {labelRight && React.isValidElement(labelRight) ? (
              labelRight
            ) : (
              <Text variant="form-label" color="ef-black" size="small">
                {labelRight}
              </Text>
            )}
          </Stack>
        )}
        <InputGroup mb="5px">
          {leftContent && <InputLeftElement>{leftContent}</InputLeftElement>}
          <ChakraInput
            // h="52px"
            pl={!leftContent ? "15px" : "45px"}
            ref={ref}
            type={type !== "password" ? type : showPassword ? "text" : type}
            sx={{
              ...inputStyles,
              ...(noBorder && {border: "0"}),
              ...(bigFontSize && {fontSize: "32px"}),
            }}
            borderColor={Boolean(error) ? "ef-red" : "ef-border"}
            data-test={dataTest}
            {...newProps.rest}
            onFocus={(e) => {
              newProps.rest.onFocus && newProps.rest.onFocus(e);
              setIsFocus(true);
            }}
            onBlur={(e) => {
              newProps.rest.onBlurCustom && newProps.rest.onBlurCustom(e);
              setIsFocus(false);
            }}
          />

          {showRightContent && (
            <InputRightElement {...rightElementProps}>
              <Stack direction="row" align="center" spacing="5px">
                {type === "password" && (
                  <Stack
                    mr="15px"
                    _hover={{cursor: "pointer"}}
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {!showPassword ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.21967 3.18842C1.9534 3.45469 1.9292 3.87135 2.14705 4.16496L2.21967 4.24908L6.25424 8.28365C4.33225 9.63312 2.89577 11.6486 2.29888 14.0331C2.1983 14.4349 2.4425 14.8422 2.84431 14.9428C3.24613 15.0433 3.6534 14.7992 3.75399 14.3973C4.28346 12.2822 5.59112 10.5082 7.33416 9.36327L9.14379 11.173C8.43628 11.8945 8 12.883 8 13.9733C8 16.1825 9.79086 17.9733 12 17.9733C13.0904 17.9733 14.0788 17.537 14.8004 16.8295L20.7197 22.749C21.0126 23.0419 21.4874 23.0419 21.7803 22.749C22.0466 22.4828 22.0708 22.0661 21.8529 21.7725L21.7803 21.6884L15.6668 15.5742L15.668 15.5727L14.4679 14.3748L11.598 11.5055L11.6 11.5047L8.71877 8.62657L8.72 8.62475L7.58672 7.49424L3.28033 3.18842C2.98744 2.89553 2.51256 2.89553 2.21967 3.18842ZM10.2041 12.2342L13.7392 15.7693C13.2892 16.2051 12.6759 16.4733 12 16.4733C10.6193 16.4733 9.5 15.354 9.5 13.9733C9.5 13.2974 9.76824 12.6841 10.2041 12.2342ZM12 6.46875C10.9997 6.46875 10.0291 6.61682 9.11109 6.89375L10.3481 8.12994C10.8839 8.02407 11.4364 7.96875 12 7.96875C15.9231 7.96875 19.3099 10.649 20.2471 14.4019C20.3475 14.8037 20.7546 15.0481 21.1565 14.9478C21.5584 14.8474 21.8028 14.4403 21.7024 14.0384C20.5994 9.62147 16.6155 6.46875 12 6.46875ZM12.1947 9.97803L15.996 13.7787C15.8942 11.7218 14.2472 10.0764 12.1947 9.97803Z"
                          fill={isFocus ? "#1F2FBA" : "#888888"}
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0004 9.97337C14.2095 9.97337 16.0004 11.7642 16.0004 13.9733C16.0004 16.1825 14.2095 17.9734 12.0004 17.9734C9.79123 17.9734 8.00037 16.1825 8.00037 13.9733C8.00037 11.7642 9.79123 9.97337 12.0004 9.97337ZM12.0004 11.4733C10.6197 11.4733 9.50037 12.5926 9.50037 13.9733C9.50037 15.354 10.6197 16.4733 12.0004 16.4733C13.3811 16.4733 14.5004 15.354 14.5004 13.9733C14.5004 12.5926 13.3811 11.4733 12.0004 11.4733ZM12.0004 6.46875C16.6139 6.46875 20.5965 9.61876 21.7015 14.0331C21.8021 14.4349 21.5579 14.8422 21.1561 14.9429C20.7543 15.0434 20.347 14.7993 20.2464 14.3974C19.3075 10.6467 15.9218 7.96875 12.0004 7.96875C8.0773 7.96875 4.69046 10.649 3.75322 14.4019C3.65286 14.8038 3.24572 15.0482 2.84385 14.9479C2.44198 14.8475 2.19756 14.4404 2.29792 14.0385C3.40101 9.62147 7.38485 6.46875 12.0004 6.46875Z"
                          fill={isFocus ? "#1F2FBA" : "#888888"}
                        />
                      </svg>
                    )}
                  </Stack>
                )}

                {rightElement}
              </Stack>
            </InputRightElement>
          )}
        </InputGroup>
        {!hideError && (
          <>
            <Collapse in={Boolean(error?.message)} animateOpacity>
              {(error?.message || customError) && (
                <Stack direction="row" align="flex-start" w="fit-content" pt="4px">
                  <Box h="16px" w="3px" bg="ef-red" />
                  <Text variant="form-error">{customError ? customError : error.message}</Text>
                </Stack>
              )}
            </Collapse>
            {(customMessage || customBottomElement) && (
              <Box mt={Boolean(error?.message) ? "0.5rem" : "0"}>
                {customMessage ? (
                  <Text variant="form-label" size="small" color="ef-gray">
                    {customMessage}
                  </Text>
                ) : (
                  customBottomElement
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
);

Input.displayName = "Input";

export const ErrorMessage: React.FC<
  React.PropsWithChildren<{error: any; customError?: string}>
> = ({error, customError}) =>
  error?.message ? (
    <Stack direction="row" align="center" w="fit-content" pt="4px">
      <Box h="16px" w="3px" bg="ef-red" />
      <Text variant="form-error">{customError ? customError : error.message}</Text>
    </Stack>
  ) : null;

export default Input;
