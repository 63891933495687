import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconISports: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M11.93 24.69C5.227 24.69 0 19.357 0 12.528 0 6.043 5.53.63 12.111.69 18.671.75 24.081 6.185 24 12.75c-.08 6.95-5.672 12.061-12.07 11.94zm4.299-15.253c-1.877.243-3.654.404-5.39.728-3.451.626-6.6 1.94-9.164 4.444-.403.384-.545.727-.282 1.212.706 1.313 1.332 2.687 2.12 3.94.18.303.887.505 1.27.424 2.262-.505 4.18-1.636 5.733-3.333 1.716-1.9 3.089-4.223 5.915-4.707-.06-.95-.122-1.738-.202-2.708zm-.303-.868c-.121-1.293-.565-1.96-1.877-2.06-1.171-.082-2.322-.587-3.492-.789-1.211-.202-2.463-.485-3.654-.384-2.059.162-3.835 1.213-4.925 2.91C.968 9.82.848 11.76 1.09 13.82c4.219-4 9.467-4.868 14.836-5.252zm.525 4.626c-.222.02-.343.02-.465.04-1.453.425-2.422 1.495-3.35 2.627-1.595 1.96-3.35 3.737-5.773 4.666-.606.223-1.211.404-2.019.687 2.16 1.253 3.957 2.89 6.52 2.02.747-.262 1.554-.545 2.1-1.05 2.563-2.424 2.825-5.657 2.987-8.99zM13.342 23.68c2.483-.384 4.42-1.313 6.056-2.808.363-.323.747-.748.868-1.192.484-1.697.383-3.394-.444-4.97-.485-.93-1.211-1.555-2.402-1.535-.182 3.899-.666 7.636-4.078 10.505zm8.175-5.475c1.353-2.343 1.655-4.565 1.433-6.889-.02-.202-.222-.424-.403-.545-1.635-1.01-3.452-1.212-5.35-1.233.081.91.162 1.718.243 2.526 3.37.545 3.916 3.07 4.077 6.141zM4.24 4.993c2.684-1.212 5.288-.768 7.912.202.788.283 1.676.364 2.745.606-1.19-2.182-2.724-3.535-4.945-3.677C7.57 1.963 5.874 3.437 4.24 4.993zm18.247 4.384c-.525-1.798-1.494-3.334-2.987-4.687-.525 1.454-1.797 1.737-2.988 2.06.424 1.758.444 1.738 2.039 1.94 1.272.182 2.543.444 3.936.687zm-9.79-7.697c.929 1.03 1.918 1.96 2.725 3.03.828 1.091 1.736 1.273 2.725.283.626-.626.606-1.07-.12-1.535-1.656-1.05-3.452-1.657-5.33-1.778z"
        fill="#C2C2C2"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .69)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconISports;
