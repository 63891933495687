import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const Message: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M21 9.373V8.54474L20.2671 8.93056L12.1165 13.2212C12.1165 13.2212 12.1164 13.2212 12.1164 13.2213C12.0578 13.2521 11.9899 13.2581 11.9278 13.2394L11.8681 13.2131L3.73287 8.93154L3 8.54583V9.374V16.75C3 17.9308 3.90918 18.8987 5.06602 18.9926L5.07614 18.9934L5.08628 18.9938L5.22981 18.9996L5.2399 19H5.25H18.75C19.9308 19 20.8987 18.0908 20.9926 16.9339L20.9934 16.9238L20.9938 16.9137L20.9996 16.7702L21 16.7601V16.75V9.373ZM18.7702 5.00041L18.7601 5H18.75H5.25C4.06925 5 3.10135 5.90918 3.00744 7.06601L3.00662 7.07613L3.00621 7.08628L3.00041 7.22981L3 7.2399V7.25V7.679V7.98087L3.26713 8.12146L11.7671 12.595L12 12.7175L12.2329 12.5949L20.7329 8.12044L21 7.97984V7.678V7.25C21 6.06925 20.0908 5.10135 18.934 5.00744L18.9238 5.00662L18.9137 5.00621L18.7702 5.00041ZM5.0868 4.50474L5.25697 4.5H18.75C20.2139 4.5 21.411 5.64417 21.4953 7.08675L21.5 7.25693V16.75C21.5 18.2139 20.3558 19.411 18.9132 19.4953L18.7431 19.5H5.25C3.78603 19.5 2.58894 18.3557 2.50474 16.9132L2.5 16.743V7.25C2.5 5.78602 3.64421 4.58894 5.0868 4.50474Z"
      fill="#727CD4"
      stroke="white"
    />
  </Icon>
);

export default Message;
