import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconIConference: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 25"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      d="M2.443 11.462c0 .187.16.348.347.348h6.922v2.592h.669V11.81h6.922c.187 0 .347-.16.347-.348V4.567h-.668v6.574H3.112V1.36h13.843v1.576h.668V1.36h1.444V.69H1v.669h1.443v10.102zM6.132 18.998a1.825 1.825 0 0 0-1.818-1.844 1.825 1.825 0 0 0-1.817 1.844c0 1.016.802 1.845 1.817 1.845a1.825 1.825 0 0 0 1.818-1.845zm-2.967 0c0-.641.508-1.176 1.15-1.176.64 0 1.148.535 1.148 1.176 0 .642-.507 1.176-1.149 1.176-.641 0-1.149-.534-1.149-1.176zM1.348 24.69h.668c0-1.362 1.096-2.485 2.459-2.485 1.336 0 2.458 1.123 2.458 2.486h.668c0-1.363 1.096-2.486 2.46-2.486 1.362 0 2.458 1.123 2.458 2.486h.668c0-1.363 1.096-2.486 2.459-2.486 1.336 0 2.459 1.123 2.459 2.486h.668c0-1.737-1.39-3.154-3.127-3.154-1.203 0-2.272.722-2.78 1.737a3.095 3.095 0 0 0-2.779-1.737c-1.202 0-2.272.722-2.78 1.737a3.094 3.094 0 0 0-2.779-1.737c-1.764 0-3.18 1.416-3.18 3.154zM11.85 18.998c0-1.015-.801-1.844-1.817-1.844a1.825 1.825 0 0 0-1.817 1.844c0 1.016.802 1.845 1.817 1.845 1.016 0 1.817-.829 1.817-1.845zm-2.966 0c0-.641.508-1.176 1.15-1.176.64 0 1.148.535 1.148 1.176 0 .642-.508 1.176-1.149 1.176s-1.149-.534-1.149-1.176zM17.57 18.998a1.825 1.825 0 0 0-1.817-1.844 1.825 1.825 0 0 0-1.818 1.844c0 1.016.802 1.845 1.818 1.845 1.015 0 1.817-.829 1.817-1.845zm-2.966 0c0-.641.507-1.176 1.149-1.176.641 0 1.149.535 1.149 1.176 0 .642-.508 1.176-1.15 1.176-.64 0-1.149-.534-1.149-1.176z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M8.99 3.418c-.106-.054-.24-.08-.347 0a.329.329 0 0 0-.187.294v5.051c0 .134.08.24.187.294.054.027.107.053.16.053a.494.494 0 0 0 .188-.053l3.902-2.539a.294.294 0 0 0 .16-.267.294.294 0 0 0-.16-.268L8.99 3.418zm.134 4.73V4.326l2.967 1.925-2.967 1.897z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconIConference;
