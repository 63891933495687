import React from "react";

type NoChildren = {children?: never};

type ConditionalWrapProps = {
  when?: boolean;
  component: React.ReactElement;
  whenTrueWrapper?: (component: React.ReactNode) => React.ReactElement;
  whenFalseWrapper?: (component: React.ReactNode) => React.ReactElement;
} & NoChildren;

const ConditionalWrapper: React.FunctionComponent<ConditionalWrapProps> = ({
  when,
  component,
  whenTrueWrapper,
  whenFalseWrapper,
}) => {
  if (when) {
    return whenTrueWrapper ? whenTrueWrapper(component) : component;
  }

  return whenFalseWrapper ? whenFalseWrapper(component) : component;
};

type ConditionalRuleProps = {
  when?: boolean;
  whenTrueComponent?: React.ReactElement;
  whenFalseComponent?: React.ReactElement;
} & NoChildren;

export const ConditionalRule: React.FunctionComponent<ConditionalRuleProps> = ({
  when,
  whenTrueComponent,
  whenFalseComponent,
}) => (when ? whenTrueComponent : whenFalseComponent);

export default ConditionalWrapper;
