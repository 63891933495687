import React from "react";
import {ErrorBoundary} from "react-error-boundary";

import {Box} from "@chakra-ui/react";

const NFTCanvas: React.FC<{
  errorBoundaryFallback?: React.ReactElement;
  nftAssetUrl?: string;
  nftId?: string;
  rotateOnly?: boolean;
}> = ({nftId, errorBoundaryFallback = <div />, nftAssetUrl, rotateOnly}) => {
  const props = rotateOnly ? {} : {ar: true, "camera-controls": true, "touch-action": "pan-y"};

  return (
    <Box w="100%" h="100%" id={`nft_${nftId || "preview"}`}>
      <ErrorBoundary fallback={errorBoundaryFallback}>
        <model-viewer
          style={{width: "100%", height: "100%"}}
          alt="NFT Preview"
          src={nftAssetUrl}
          auto-rotate
          shadow-intensity="1"
          autoplay
          {...props}
        />
      </ErrorBoundary>
    </Box>
  );
};

export default NFTCanvas;
