import {baseApi as api} from "../baseOrgQuery/baseOrgApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNftappApiAccountByAccountIdStyle: build.query<
      GetNftappApiAccountByAccountIdStyleApiResponse,
      GetNftappApiAccountByAccountIdStyleApiArg
    >({
      query: (queryArg) => ({url: `/nftapp/api/account/${queryArg.accountId}/style/`}),
    }),
    postNftappApiAccountByAccountIdStyle: build.mutation<
      PostNftappApiAccountByAccountIdStyleApiResponse,
      PostNftappApiAccountByAccountIdStyleApiArg
    >({
      query: (queryArg) => ({
        url: `/nftapp/api/account/${queryArg.accountId}/style/`,
        method: "POST",
        body: queryArg.stylePayloadAf2A349,
      }),
    }),
    getNftappApiAccountByAccountIdTemplate: build.query<
      GetNftappApiAccountByAccountIdTemplateApiResponse,
      GetNftappApiAccountByAccountIdTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/nftapp/api/account/${queryArg.accountId}/template/`,
        params: {style: queryArg.style},
      }),
    }),
    postNftappApiAccountByAccountIdTemplate: build.mutation<
      PostNftappApiAccountByAccountIdTemplateApiResponse,
      PostNftappApiAccountByAccountIdTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/nftapp/api/account/${queryArg.accountId}/template/`,
        method: "POST",
        body: queryArg.templateFormRequestB01E989,
      }),
    }),
    getNftappApiEnums: build.query<GetNftappApiEnumsApiResponse, GetNftappApiEnumsApiArg>({
      query: () => ({url: `/nftapp/api/enums/`}),
    }),
    getNftappApiStyle: build.query<GetNftappApiStyleApiResponse, GetNftappApiStyleApiArg>({
      query: () => ({url: `/nftapp/api/style/`}),
    }),
    postNftappApiStyle: build.mutation<PostNftappApiStyleApiResponse, PostNftappApiStyleApiArg>({
      query: (queryArg) => ({
        url: `/nftapp/api/style/`,
        method: "POST",
        body: queryArg.stylePayloadAf2A349,
      }),
    }),
    getNftappApiStyleByStyleId: build.query<
      GetNftappApiStyleByStyleIdApiResponse,
      GetNftappApiStyleByStyleIdApiArg
    >({
      query: (queryArg) => ({url: `/nftapp/api/style/${queryArg.styleId}/`}),
    }),
    patchNftappApiStyleByStyleId: build.mutation<
      PatchNftappApiStyleByStyleIdApiResponse,
      PatchNftappApiStyleByStyleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/nftapp/api/style/${queryArg.styleId}/`,
        method: "PATCH",
        body: queryArg.stylePayloadAf2A349,
      }),
    }),
    postNftappApiTemplate: build.mutation<
      PostNftappApiTemplateApiResponse,
      PostNftappApiTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/nftapp/api/template/`,
        method: "POST",
        body: queryArg.templateFormRequestB01E989,
      }),
    }),
    getNftappApiTemplateByTemplateId: build.query<
      GetNftappApiTemplateByTemplateIdApiResponse,
      GetNftappApiTemplateByTemplateIdApiArg
    >({
      query: (queryArg) => ({url: `/nftapp/api/template/${queryArg.templateId}/`}),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as beNftOrgApi};
export type GetNftappApiAccountByAccountIdStyleApiResponse = /** status 200 OK */ StyleListResponse;
export type GetNftappApiAccountByAccountIdStyleApiArg = {
  accountId: string;
};
export type PostNftappApiAccountByAccountIdStyleApiResponse = /** status 200 OK */ StyleResponse2;
export type PostNftappApiAccountByAccountIdStyleApiArg = {
  accountId: string;
  stylePayloadAf2A349: StylePayload;
};
export type GetNftappApiAccountByAccountIdTemplateApiResponse =
  /** status 200 OK */ TemplateListResponse;
export type GetNftappApiAccountByAccountIdTemplateApiArg = {
  accountId: string;
  style?: string;
};
export type PostNftappApiAccountByAccountIdTemplateApiResponse =
  /** status 201 Created */ TemplateResponse2;
export type PostNftappApiAccountByAccountIdTemplateApiArg = {
  accountId: string;
  templateFormRequestB01E989: TemplateFormRequest;
};
export type GetNftappApiEnumsApiResponse = /** status 200 OK */ ApiEnumsResponse;
export type GetNftappApiEnumsApiArg = void;
export type GetNftappApiStyleApiResponse = /** status 200 OK */ StyleListResponse;
export type GetNftappApiStyleApiArg = void;
export type PostNftappApiStyleApiResponse = /** status 200 OK */ StyleResponse2;
export type PostNftappApiStyleApiArg = {
  stylePayloadAf2A349: StylePayload;
};
export type GetNftappApiStyleByStyleIdApiResponse = /** status 200 OK */ StyleResponse2;
export type GetNftappApiStyleByStyleIdApiArg = {
  styleId: string;
};
export type PatchNftappApiStyleByStyleIdApiResponse = /** status 200 OK */ StyleResponse2;
export type PatchNftappApiStyleByStyleIdApiArg = {
  styleId: string;
  stylePayloadAf2A349: StylePayload;
};
export type PostNftappApiTemplateApiResponse = /** status 201 Created */ TemplateResponse2;
export type PostNftappApiTemplateApiArg = {
  templateFormRequestB01E989: TemplateFormRequest;
};
export type GetNftappApiTemplateByTemplateIdApiResponse = /** status 200 OK */ TemplateResponse2;
export type GetNftappApiTemplateByTemplateIdApiArg = {
  templateId: string;
};
export type KindEnum = "color" | "gradient" | "text" | "template";
export type StyleFragment = {
  coords: number[];
  kind: KindEnum;
  name?: string;
  properties?: object;
  readonly?: boolean;
  value: string | (number | string)[];
};
export type MimeEnum =
  | "image/jpeg"
  | "image/png"
  | "model/gltf+json"
  | "model/gltf-binary"
  | "application/zip";
export type StyleStatusEnum = "processing" | "finalized";
export type StyleResponse = {
  account?: string;
  created_at: string;
  fragments: StyleFragment[];
  id: string;
  mimetype: MimeEnum;
  name?: string;
  properties?: object;
  size: number[];
  status: StyleStatusEnum;
  suffix: string;
  updated_at: string;
  url: string;
  url_preview: string;
};
export type StyleListResponse = {
  items: StyleResponse[];
};
export type KindEnum2 = "color" | "gradient" | "text" | "template";
export type StyleFragment2 = {
  coords: number[];
  kind: KindEnum2;
  name?: string;
  properties?: object;
  readonly?: boolean;
  value: string | (number | string)[];
};
export type MimeEnum2 =
  | "image/jpeg"
  | "image/png"
  | "model/gltf+json"
  | "model/gltf-binary"
  | "application/zip";
export type StyleStatusEnum2 = "processing" | "finalized";
export type StyleResponse2 = {
  account?: string;
  created_at: string;
  fragments: StyleFragment2[];
  id: string;
  mimetype: MimeEnum2;
  name?: string;
  properties?: object;
  size: number[];
  status: StyleStatusEnum2;
  suffix: string;
  updated_at: string;
  url: string;
  url_preview: string;
};
export type KindEnum3 = "color" | "gradient" | "text" | "template";
export type StyleFragment3 = {
  coords: number[];
  kind: KindEnum3;
  name?: string;
  properties?: object;
  readonly?: boolean;
  value: string | (number | string)[];
};
export type MimeEnum3 =
  | "image/jpeg"
  | "image/png"
  | "model/gltf+json"
  | "model/gltf-binary"
  | "application/zip";
export type StylePayload = {
  fragments: StyleFragment3[];
  mimetype: MimeEnum3;
  name: string;
  properties?: object;
  size?: number[];
};
export type TemplateResponse = {
  account?: string;
  created_at: string;
  id: string;
  mimetype: string;
  properties?: object;
  updated_at: string;
  url: string;
};
export type TemplateListResponse = {
  items: TemplateResponse[];
};
export type TemplateResponse2 = {
  account?: string;
  created_at: string;
  id: string;
  mimetype: string;
  properties?: object;
  updated_at: string;
  url: string;
};
export type TemplateFormRequest = {
  file: Blob;
};
export type ApiEnumsResponse = {
  fonts: {
    [key: string]: string;
  };
};
export const {
  useGetNftappApiAccountByAccountIdStyleQuery,
  usePostNftappApiAccountByAccountIdStyleMutation,
  useGetNftappApiAccountByAccountIdTemplateQuery,
  usePostNftappApiAccountByAccountIdTemplateMutation,
  useGetNftappApiEnumsQuery,
  useGetNftappApiStyleQuery,
  usePostNftappApiStyleMutation,
  useGetNftappApiStyleByStyleIdQuery,
  usePatchNftappApiStyleByStyleIdMutation,
  usePostNftappApiTemplateMutation,
  useGetNftappApiTemplateByTemplateIdQuery,
} = injectedRtkApi;
