import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconFilter: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M9.75 21C9.60958 21.0004 9.47186 20.9615 9.3525 20.8875C9.24442 20.8199 9.15533 20.726 9.09366 20.6144C9.03198 20.5029 8.99975 20.3775 9 20.25V13.035L3.21001 6.6075C2.59034 5.91752 2.24832 5.02239 2.25001 4.095V3.75C2.25001 3.55109 2.32902 3.36032 2.46968 3.21967C2.61033 3.07902 2.80109 3 3.00001 3H21C21.1989 3 21.3897 3.07902 21.5303 3.21967C21.671 3.36032 21.75 3.55109 21.75 3.75V4.095C21.7517 5.02239 21.4097 5.91752 20.79 6.6075L15 13.035V17.07C15.0008 17.4888 14.8847 17.8996 14.6648 18.256C14.4448 18.6125 14.1297 18.9004 13.755 19.0875L10.0875 20.9175C9.98301 20.971 9.86741 20.9993 9.75 21ZM3.78751 4.5C3.86183 4.91045 4.04879 5.29216 4.3275 5.6025L10.3275 12.2475C10.4444 12.3883 10.5057 12.5671 10.5 12.75V19.035L13.0875 17.745C13.2123 17.6821 13.3171 17.5856 13.39 17.4663C13.4629 17.347 13.501 17.2098 13.5 17.07V12.75C13.5005 12.5641 13.57 12.385 13.695 12.2475L19.695 5.6025C19.9792 5.29392 20.1714 4.91205 20.25 4.5H3.78751Z"
      fill="#727CD4"
    />
  </Icon>
);

export default IconFilter;
