import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconIFoodAndDrink: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      d="M4.2 22.2a1.2 1.2 0 1 0-2.4 0 1.2 1.2 0 0 0 2.4 0zm-1.6 0a.4.4 0 1 1 .8 0 .4.4 0 0 1-.8 0z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M24.6 13.4h-1.2A10.4 10.4 0 0 0 13.4 3V1.8h.8V1h-2.4v.8h.8V3a10.4 10.4 0 0 0-10 10.4H1.4a.4.4 0 0 0-.4.4 2.8 2.8 0 0 0 2.8 2.8h2.884l-1.064.884a.4.4 0 0 0-.22-.084H1v.8h4v6H1v.8h4.4a.4.4 0 0 0 .4-.4v-1.2h9.524a2.8 2.8 0 0 0 1.784-.644l4.836-4a1.564 1.564 0 0 0 .432-2.076c0-.028-.04-.052-.056-.08A2.8 2.8 0 0 0 25 13.8a.4.4 0 0 0-.4-.4zM13 3.8a9.6 9.6 0 0 1 9.6 9.6H3.4A9.6 9.6 0 0 1 13 3.8zm8.456 14.316L16.6 22.14a2 2 0 0 1-1.276.46H5.8v-4.212l1.8-1.508a1.2 1.2 0 0 1 .768-.28h5.2a.6.6 0 0 1 0 1.2H11a1.2 1.2 0 0 0 0 2.4h4.8a.4.4 0 0 0 .232-.076l4.568-3.268a.768.768 0 0 1 1.096.228.776.776 0 0 1-.24 1.032zM14.85 16.6h4.731l-3.92 2.8H11a.4.4 0 0 1 0-.8h2.6a1.4 1.4 0 0 0 1.4-1.4 1.382 1.382 0 0 0-.152-.6zm7.351-.8H3.8a2 2 0 0 1-1.96-1.6h22.32a2 2 0 0 1-1.96 1.6z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M5.651 8.565 6.32 9c.21-.321.445-.626.7-.912l-.6-.532c-.28.317-.536.654-.768 1.008zM7.528 7.568A7.972 7.972 0 0 1 13 5.4v-.8A8.76 8.76 0 0 0 7 7l.528.568z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconIFoodAndDrink;
