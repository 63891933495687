import StackdriverErrorReporter from "stackdriver-errors-js";

const error = new StackdriverErrorReporter();

const initGoogleReporting = () => {
  if (process.env.NEXT_PUBLIC_ENV_TYPE === "local") return;

  error.start({
    key: process.env.NEXT_PUBLIC_ERROR_REPORTING_API_KEY || "",
    projectId: process.env.NEXT_PUBLIC_ERROR_REPORTING_PROJECT_ID || "",
    service: "feapp",
    disabled: process.env.NEXT_PUBLIC_ENV_TYPE === "local",
    targetUrl: process.env.NEXT_PUBLIC_API_URL + "/py3/error/",
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reportError = (message: any): void => {
  if (process.env.NEXT_PUBLIC_ENV_TYPE === "local") {
    // eslint-disable-next-line no-console
    return console.error(message);
  }

  error.report(message);
};

export {error as errorReporting, initGoogleReporting, reportError};
