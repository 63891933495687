import {ButtonLink} from "@ef-org/components";
import {InitialUseCreateModalType, useCreateModal, useModal} from "@ef-org/modals";

import React from "react";

import {Box, Heading, Stack, Text} from "@chakra-ui/react";

type Props = {
  title?: string;
  message?: string;
  smallText?: string;
  customSection?: React.ReactNode;
  hideVisitorId?: boolean;
  onClose?: () => void;
};

const ErrorModal: React.FC<Props> = ({
  title,
  message,
  customSection,
  smallText,
  hideVisitorId,
  onClose,
}) => {
  const {closeModal} = useModal("ErrorModal");

  return (
    <Box m="2rem">
      <Heading variant="h3" w="100%" textAlign="center" pb="2rem">
        {title || "Something went wrong"}
      </Heading>

      {message && (
        <Text mb="2rem" textAlign="center" w="100%">
          {message}
        </Text>
      )}

      {customSection && customSection}

      <Stack align="center">
        <ButtonLink
          variant="blue"
          onClick={() => {
            closeModal();
            onClose && onClose();
          }}
        >
          Try again
        </ButtonLink>
        {smallText && (
          <Text fontSize="sm" color="ef-border">
            {smallText}
          </Text>
        )}
        {/* 
        {!hideVisitorId && visitorId && (
          <Text mb="-1rem" opacity=".8" fontSize="12px" color="ef-border">
            Your request ID #{visitorId}
          </Text>
        )} */}
      </Stack>
    </Box>
  );
};

export const useErrorModal: InitialUseCreateModalType<Props> = () =>
  useCreateModal("ErrorModal", ErrorModal, {
    closeOnEsc: false,
    closeOnOverlayClick: false,
    hideCloseButton: true,
    size: "lg",
  });
