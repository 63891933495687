import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";

interface ValidationState {
  results: Record<string, any>;
}

const initialState: ValidationState = {
  results: {},
};

const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    setValidationResult(state, action: PayloadAction<{id: string; result: any}>) {
      state.results[action.payload.id] = action.payload.result;
    },
  },
});

// SELECTORS
export const getValidationResults = (state: RootState) => state.validation.results;

export const {setValidationResult} = validationSlice.actions;

export default validationSlice;
