import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconXMark: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    stroke="#A6A6A6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M18 6.90771L6 18.9077"
      stroke="#9FA6E1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6.90771L18 18.9077"
      stroke="#9FA6E1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconXMark;
