import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconICharity: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 25"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      d="M10.624 22.326c.073.037.109.073.182.073a.278.278 0 0 0 .182-.073c.145-.073 3.527-2.254 3.527-4.581a2.553 2.553 0 0 0-2.546-2.546c-.4 0-.8.11-1.163.291a2.623 2.623 0 0 0-1.164-.29 2.553 2.553 0 0 0-2.545 2.545c.036 2.327 3.381 4.472 3.527 4.581zm-.945-6.436c.327 0 .69.11.945.29.109.074.255.074.4 0 .29-.18.618-.29.945-.29 1.018 0 1.819.836 1.819 1.818 0 1.673-2.291 3.382-2.982 3.855-.691-.473-2.982-2.182-2.982-3.855.036-.981.836-1.818 1.854-1.818z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
    <path
      d="M4.225 20.362a6.956 6.956 0 0 0 6.472 4.328 6.984 6.984 0 0 0 6.982-6.982V8.29c0-.946-.8-1.746-1.745-1.746-.4 0-.727.11-1.018.327v-.763c0-.982-.8-1.746-1.746-1.746-.4 0-.727.11-1.018.328V2.435c0-.945-.8-1.746-1.745-1.746-.946 0-1.746.8-1.746 1.746v.764a1.7 1.7 0 0 0-1.018-.328c-.982 0-1.745.8-1.745 1.746v8.254l-1.673-1.963a1.854 1.854 0 0 0-2.655-.182c-.69.69-.727 1.309-.254 2.509l2.909 7.127zM2.079 11.2c.473-.4 1.164-.364 1.6.145l2.328 2.728c.218.254.618.072.618-.255V4.58a1.04 1.04 0 0 1 1.018-1.019A1.04 1.04 0 0 1 8.66 4.582v8.8c0 .218.145.363.364.363.218 0 .363-.145.363-.364V2.435a1.04 1.04 0 0 1 1.018-1.018 1.04 1.04 0 0 1 1.018 1.018v10.982c0 .218.146.364.364.364s.364-.146.364-.364V6.108A1.04 1.04 0 0 1 13.17 5.09a1.04 1.04 0 0 1 1.018 1.018v7.31c0 .217.146.363.364.363s.363-.146.363-.364V8.29a1.04 1.04 0 0 1 1.018-1.018 1.04 1.04 0 0 1 1.018 1.018v9.418c0 3.455-2.8 6.254-6.254 6.254a6.207 6.207 0 0 1-5.781-3.89l-2.91-7.164c-.472-1.091-.29-1.346.073-1.71z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconICharity;
