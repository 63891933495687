import React from "react";

import {Text} from "@chakra-ui/react";

export const EventStatusLabel: React.FC<{isPublished?: boolean}> = ({isPublished}) => {
  return isPublished ? (
    <Text
      color="#4754C6"
      py="0.3rem"
      px="1rem"
      h="fit-content"
      borderRadius="12px"
      bg="linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)"
    >
      Published
    </Text>
  ) : (
    <Text color="#4754C6" py="0.3rem" h="fit-content" px="1rem" borderRadius="12px" bg="#E9EAF8">
      Draft
    </Text>
  );
};

export const TicketStatusLabel: React.FC<{isPublished?: boolean}> = ({isPublished}) => {
  return isPublished ? (
    <Text
      color="#4754C6"
      py="0.3rem"
      px="1rem"
      h="fit-content"
      borderRadius="12px"
      bg="linear-gradient(117.64deg, #A5FFB9 28.1%, #58F5FF 176.66%)"
    >
      Published
    </Text>
  ) : (
    <Text color="#4754C6" py="0.3rem" h="fit-content" px="1rem" borderRadius="12px" bg="#E9EAF8">
      Unpublished
    </Text>
  );
};

export const OrderStatusLabel: React.FC<{isPaid?: boolean}> = ({isPaid}) => {
  return isPaid ? (
    <Text color="#2D802B" py="0.3rem" px="1rem" h="fit-content" borderRadius="12px" bg="#D5F8D4">
      Paid
    </Text>
  ) : (
    <Text color="#B84930" py="0.3rem" h="fit-content" px="1rem" borderRadius="12px" bg="#F6D6CF">
      Unpaid
    </Text>
  );
};
