import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconTarget: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    cursor="pointer"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M9.9982 0.617188C11.2019 0.617188 12.3559 0.829818 13.4248 1.21957L12.231 2.41341C11.5195 2.22027 10.7709 2.11719 9.9982 2.11719C5.30261 2.11719 1.49609 5.92371 1.49609 10.6193C1.49609 15.3149 5.30261 19.1214 9.9982 19.1214C14.6938 19.1214 18.5003 15.3149 18.5003 10.6193C18.5003 9.8477 18.3975 9.1 18.2049 8.38938L19.3989 7.19538C19.788 8.26349 20.0003 9.4166 20.0003 10.6193C20.0003 16.1433 15.5222 20.6214 9.9982 20.6214C4.47419 20.6214 -0.00390625 16.1433 -0.00390625 10.6193C-0.00390625 5.09529 4.47419 0.617188 9.9982 0.617188ZM9.9982 4.6193C10.5168 4.6193 11.02 4.6851 11.5 4.80881L11.5001 6.37604C11.0304 6.20978 10.5248 6.1193 9.9982 6.1193C7.51289 6.1193 5.49817 8.13402 5.49817 10.6193C5.49817 13.1046 7.51289 15.1193 9.9982 15.1193C12.4834 15.1193 14.4982 13.1046 14.4982 10.6193C14.4982 10.0933 14.4079 9.5885 14.2421 9.1193H15.8091C15.9325 9.5987 15.9982 10.1014 15.9982 10.6193C15.9982 13.933 13.3119 16.6193 9.9982 16.6193C6.68446 16.6193 3.99817 13.933 3.99817 10.6193C3.99817 7.30559 6.68446 4.6193 9.9982 4.6193ZM11.9982 10.6193C11.9982 11.7239 11.1027 12.6193 9.9982 12.6193C8.8936 12.6193 7.99817 11.7239 7.99817 10.6193C7.99817 9.5147 8.8936 8.6193 9.9982 8.6193C10.2758 8.6193 10.5402 8.6759 10.7805 8.7781L12.5001 7.05854L12.5 3.86934C12.5 3.67042 12.579 3.47965 12.7197 3.33899L15.2196 0.838928C15.4341 0.624418 15.7567 0.560247 16.0369 0.676327C16.3172 0.792417 16.4999 1.0659 16.4999 1.36925V4.11927L19.2501 4.11932C19.5535 4.11932 19.827 4.30206 19.943 4.58232C20.0591 4.86257 19.995 5.18516 19.7805 5.39965L17.2804 7.89965C17.1397 8.0403 16.949 8.11931 16.7501 8.11931L13.5607 8.11932L11.8405 9.8395C11.942 10.0791 11.9982 10.3427 11.9982 10.6193ZM16.4394 6.61931L17.4395 5.61929L15.7499 5.61926C15.3357 5.61925 14.9999 5.28346 14.9999 4.86926V3.17997L14 4.17995L14.0001 6.56035C14.0104 6.56956 14.0205 6.57911 14.0303 6.58898C14.0402 6.59889 14.0498 6.60901 14.059 6.61932L16.4394 6.61931Z"
      fill="url(#paint0_linear_6320_103652)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6320_103652"
        x1="20.0003"
        y1="0.617187"
        x2="7.74268"
        y2="22.3076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3356FF" />
        <stop offset="1" stop-color="#0F2489" />
      </linearGradient>
    </defs>
  </Icon>
);

export default IconTarget;
