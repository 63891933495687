import {dataCys} from "@@shared";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

import React from "react";

import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useMediaQuery,
} from "@chakra-ui/react";

type ModalBaseProps = Partial<Omit<ModalProps, "children">> & {
  hideCloseButton?: boolean;
  isFullOnMobile?: boolean;
  borderRadius?: ModalBodyProps["borderRadius"];
};

type ModalComponentToHandler = {
  modalId: string;
  modalStyleProps: ModalBaseProps;
  component: JSX.Element;
};

type ModalProviderType = {
  setNewModal: (props: ModalComponentToHandler) => void;
  closeModal: (modalId: string) => void;
  closeAllModals: () => void;
  components: Array<ModalComponentToHandler>;
};

type UseCreateModalType<T> = {
  closeModal: ModalProviderType["closeModal"];
  closeAllModals: ModalProviderType["closeAllModals"];
  openModal: T extends undefined ? () => void : (props: T) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export type InitialUseCreateModalType<T extends unknown = undefined> = (
  modalProps?: ModalBaseProps
) => UseCreateModalType<T>;

export type ReactModalComponent<T = unknown> = React.FunctionComponent<
  {closeModal: () => void} & T
>;

type UseModalType = {closeModal: () => void; closeAllModals: () => void};

function useModal(modalId: string): UseModalType {
  const {closeModal, closeAllModals} = useModalBuilder(
    ({actions: {closeAllModals, closeModal}}) => ({
      closeAllModals,
      closeModal,
    })
  );

  return {closeModal: () => closeModal(modalId), closeAllModals};
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
function useCreateModal<ComponentProps extends unknown = undefined>(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any | React.ReactNode,
  modalStyleProps: ModalBaseProps = {}
): UseCreateModalType<ComponentProps> {
  const {setNewModal, closeModal, closeAllModals} = useModalBuilder(
    ({actions: {closeAllModals, closeModal, setNewModal}}) => ({
      closeAllModals,
      closeModal,
      setNewModal,
    })
  );

  return {
    closeModal,
    closeAllModals,
    //@ts-expect-error TS cant handle 2 types
    openModal: (props) => {
      setNewModal({
        modalId: id,
        modalStyleProps: {
          size: "md",
          closeOnEsc: false,
          closeOnOverlayClick: false,
          ...modalStyleProps,
        },
        //@ts-expect-error TS cant handle React elements as JSX
        component: <Component {...(props ? props : {})} closeModal={() => closeModal(id)} />,
      });
    },
  };
}

type StoreType = {
  modals: Array<ModalComponentToHandler>;
  actions: {
    setNewModal: (props: ModalComponentToHandler) => void;
    closeModal: (modalId: string) => void;
    closeAllModals: () => void;
  };
};

const useModalBuilder = create(
  subscribeWithSelector<StoreType>((set) => ({
    modals: [],
    actions: {
      closeAllModals: () => set(() => ({modals: []})),
      setNewModal: (props) => set(({modals}) => ({modals: [...modals, props]})),
      closeModal: (modalId) =>
        set(({modals}) => ({modals: modals.filter((m) => m.modalId !== modalId)})),
    },
  }))
);

export {useCreateModal, useModal};

const ModalsHandler: React.FC = () => {
  const [isMobile] = useMediaQuery("(max-width: 30.1em)", {
    ssr: true,
    fallback: false,
  });

  const {modal, closeModal} = useModalBuilder(({modals, actions: {closeModal}}) => ({
    modal: modals[modals.length - 1],
    closeModal,
  }));

  const handleCloseModal = (): void => {
    modal && closeModal(modal.modalId || "");
  };

  return (
    <>
      {modal && (
        <Modal
          isOpen={Boolean(modal)}
          onClose={handleCloseModal}
          isCentered={modal.modalStyleProps.isFullOnMobile && isMobile ? false : true}
          {...modal?.modalStyleProps}
          size={[
            modal.modalStyleProps.isFullOnMobile ? "full" : (modal.modalStyleProps.size as string),
            modal.modalStyleProps.size as string,
          ]}
        >
          <ModalOverlay />
          <ModalContent borderRadius={modal?.modalStyleProps?.borderRadius}>
            {!modal?.modalStyleProps?.hideCloseButton && (
              <ModalCloseButton zIndex="1" data-cy={dataCys.modals.settings.closeBtn} />
            )}
            <ModalBody
              p="0"
              maxH={["unset", "80vh", "80vh"]}
              overflowX="auto"
              borderRadius={modal?.modalStyleProps?.borderRadius}
            >
              {modal?.component}
            </ModalBody>

            {/* <ModalFooter>Buttons...</ModalFooter> */}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const ModalProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <>
      {children}
      <ModalsHandler />
    </>
  );
};

export default ModalProvider;
