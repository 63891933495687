import {decryptDataToString, encryptDataToString} from "@ef-org/utils";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import Cookies from "js-cookie";

import {useRouter} from "next/router";

import {UserRole} from "../../api/__generated__/beOrgApi.generated";

const poolData = {
  UserPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

export const getCurrentUser = () => {
  const userPool = new CognitoUserPool(poolData);

  const cognitoUser = userPool.getCurrentUser();

  return cognitoUser;
};

export const getUser = (email) => {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool: new CognitoUserPool(poolData),
  });
};

//todo: any is here just for testing
export const fetchUserData = async (): Promise<any> =>
  await new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession(async (err, _session) => {
        if (err) {
          reject();
        } else {
          const attributes: Record<string, any> = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {};

                for (let attribute of attributes) {
                  const {Name, Value} = attribute;
                  results[Name] = Value;
                }

                resolve(results);
              }
            });
          });

          resolve({
            ...(attributes as any),
          });
        }
      });
    } else {
      reject();
    }
  });

export const authenticateUser = (
  email: string,
  password: string
): Promise<{token?: string; errorMessage?: string; userData?: any; id?: string}> => {
  const authenticationData = {
    Username: email,
    Password: password,
  };

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      async onSuccess(result) {
        const userData = await fetchUserData();
        const idToken = await result.getIdToken();
        const refreshToken = await result.getRefreshToken();
        Cookies.set("accessToken", idToken?.getJwtToken(), {expires: idToken.getExpiration()});
        Cookies.set("refreshToken", refreshToken?.getToken(), {expires: 30});
        Cookies.set("userId", idToken.payload.sub, {expires: 12});
        Cookies.set("userName", userData.given_name, {expires: 12});

        Cookies.set("role", encryptDataToString(userData["custom:role"]), {expires: 12});
        Cookies.set("teamOwnerId", userData["custom:team_owner_id"], {expires: 12});

        setLastLoginAttribute();
        resolve({
          userData,
          id: idToken.payload.sub,
        });
      },
      onFailure(err) {
        resolve({errorMessage: err?.message});
      },
    });
  });
};

export const refreshSession = (): Promise<{token: string}> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCurrentUser();
    const RefreshToken = Cookies.get("refreshToken");
    cognitoUser.refreshSession(new CognitoRefreshToken({RefreshToken}), (err, newSession) => {
      if (err) {
        return reject(err);
      }
      if (newSession) {
        const idToken = newSession.getIdToken();
        const refreshToken = newSession.getRefreshToken();

        Cookies.set("accessToken", idToken?.getJwtToken(), {
          expires: new Date(idToken.getExpiration() * 1000),
        });
        Cookies.set("refreshToken", refreshToken?.getToken(), {expires: 30});
        resolve({token: idToken.getJwtToken()});
      } else {
        reject(new Error("Session refresh unsuccessful"));
      }
    });
  });
};

const setLastLoginAttribute = () => {
  const cognitoUser = getCurrentUser();
  const loginTimestamp = {
    Name: "custom:last_login_datetime",
    Value: new Date().toISOString(),
  };
  const attribute = new CognitoUserAttribute(loginTimestamp);

  cognitoUser.getSession(async (err, _session) => {
    cognitoUser.updateAttributes([attribute], (res) => {});
  });
};

export const verifyAccountByCode = (
  email,
  code
): Promise<{status: boolean; errorMessage?: string}> => {
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve) => {
    cognitoUser.confirmRegistration(code, true, (err) => {
      if (err) {
        return resolve({status: false, errorMessage: err.message});
      }
      return resolve({status: true});
    });
  });
};

export const registerUser = (
  email: string,
  password: string,
  additionalData: {given_name: string; family_name: string},
  role: UserRole,
  teamOwner?: string
): Promise<{
  isCreated: boolean;
  errorMessage?: string;
  errorKey?: string;
  userSub?: string;
}> => {
  const userPool = new CognitoUserPool(poolData);

  const dataEmail = {
    Name: "email",
    Value: email,
  };

  const attributeEmail = new CognitoUserAttribute(dataEmail);

  let userAttributes = [attributeEmail];
  Object.keys(additionalData).forEach((key) => {
    const attribute = {
      Name: key,
      Value: additionalData[key],
    };
    userAttributes.push(new CognitoUserAttribute(attribute));
  });

  userAttributes.push(new CognitoUserAttribute({Name: "custom:role", Value: role}));
  if ((role === "member" || role === "admin") && teamOwner) {
    userAttributes.push(new CognitoUserAttribute({Name: "custom:team_owner_id", Value: teamOwner}));
  }

  return new Promise((resolve) => {
    userPool.signUp(email, password, userAttributes, null, (err, res) => {
      if (err) {
        //@ts-ignore
        return resolve({isCreated: false, errorMessage: err.message, errorKey: err?.code});
      }
      return resolve({isCreated: true, userSub: res?.userSub});
    });
  });
};

export const logout = () => {
  const user = userPool.getCurrentUser();
  user.signOut();
  window.location.href = "/login";
};

export const changePassword = (
  oldPassword: string,
  newPassword: string
): Promise<{
  isChanged: boolean;
  errorMessage?: string;
  errorKey?: string;
}> => {
  const user = userPool.getCurrentUser();

  return new Promise((resolve) => {
    user.getSession(async (err, _session) => {
      if (err) {
        return resolve({isChanged: false, errorMessage: err.message, errorKey: err?.code});
      } else {
        user.changePassword(oldPassword, newPassword, (error, _) => {
          if (error) {
            return resolve({isChanged: false, errorMessage: error.message, errorKey: error.name});
          }
          return resolve({isChanged: true});
        });
      }
    });
  });
};

export const getUserName = () => {
  return Cookies.get("userName");
};

export const getUserId = () => {
  return Cookies.get("userId");
};

export const isUserAuthorized = (requiredPermissions: UserRole) => {
  const roleCookie = Cookies.get("role");
  if (!roleCookie) {
    return false;
  }

  const role = decryptDataToString(roleCookie) as string;
  if (!role) {
    return false;
  }

  const roleLevels = {
    owner: 2,
    admin: 1,
    member: 0,
  };

  return roleLevels[requiredPermissions] <= roleLevels[role];
};
