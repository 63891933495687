import {baseApi as api} from "../baseOrgQuery/baseOrgApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    rootGet: build.query<RootGetApiResponse, RootGetApiArg>({
      query: () => ({url: `/`}),
    }),
    healthHealthGet: build.query<HealthHealthGetApiResponse, HealthHealthGetApiArg>({
      query: () => ({url: `/health`}),
    }),
    getUserUsersUserIdGet: build.query<
      GetUserUsersUserIdGetApiResponse,
      GetUserUsersUserIdGetApiArg
    >({
      query: (queryArg) => ({url: `/users/${queryArg.userId}`}),
    }),
    createUserUsersUserIdPut: build.mutation<
      CreateUserUsersUserIdPutApiResponse,
      CreateUserUsersUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.userCreate,
      }),
    }),
    updateUserUsersUserIdPatch: build.mutation<
      UpdateUserUsersUserIdPatchApiResponse,
      UpdateUserUsersUserIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: "PATCH",
        body: queryArg.userUpdate,
      }),
    }),
    getTeamMembersUsersUserIdTeamGet: build.query<
      GetTeamMembersUsersUserIdTeamGetApiResponse,
      GetTeamMembersUsersUserIdTeamGetApiArg
    >({
      query: (queryArg) => ({url: `/users/${queryArg.userId}/team`}),
    }),
    verifyCaptchaUsersVerifyCaptchaPost: build.mutation<
      VerifyCaptchaUsersVerifyCaptchaPostApiResponse,
      VerifyCaptchaUsersVerifyCaptchaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/verify-captcha`,
        method: "POST",
        body: queryArg.userVerificationRequest,
      }),
    }),
    getOrganizerUsersUserIdOrganizersOrganizerIdGet: build.query<
      GetOrganizerUsersUserIdOrganizersOrganizerIdGetApiResponse,
      GetOrganizerUsersUserIdOrganizersOrganizerIdGetApiArg
    >({
      query: (queryArg) => ({url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}`}),
    }),
    updateOrganizerUsersUserIdOrganizersOrganizerIdPatch: build.mutation<
      UpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchApiResponse,
      UpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}`,
        method: "PATCH",
        body: queryArg.organizerUpdate,
      }),
    }),
    getOrganizerUsersUserIdOrganizersOrganizerIdStatsPost: build.mutation<
      GetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostApiResponse,
      GetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}/stats`,
        method: "POST",
        body: queryArg.organizerStatsPost,
      }),
    }),
    getCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGet: build.query<
      GetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetApiResponse,
      GetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}/attendees`,
        params: {eventFilter: queryArg.eventFilter},
      }),
    }),
    sendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPost: build.mutation<
      SendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostApiResponse,
      SendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}/community_email`,
        method: "POST",
        body: queryArg.communityMessagePayload,
      }),
    }),
    setupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPost: build.mutation<
      SetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostApiResponse,
      SetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}/ryft-setup`,
        method: "POST",
        body: queryArg.setupRyftPaymentMethodPayload,
      }),
    }),
    completeRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePost: build.mutation<
      CompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostApiResponse,
      CompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/organizers/${queryArg.organizerId}/ryft-complete`,
        method: "POST",
        body: queryArg.completeRyftPaymentMethodPayload,
      }),
    }),
    getAvailablePlansSubscriptionsPlansGet: build.query<
      GetAvailablePlansSubscriptionsPlansGetApiResponse,
      GetAvailablePlansSubscriptionsPlansGetApiArg
    >({
      query: () => ({url: `/subscriptions/plans`}),
    }),
    getSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGet: build.query<
      GetSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGetApiResponse,
      GetSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/subscriptions/${queryArg.subscriptionId}`,
      }),
    }),
    getOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGet: build.query<
      GetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetApiResponse,
      GetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/subscriptions`}),
    }),
    getOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGet: build.query<
      GetOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGetApiResponse,
      GetOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/merchandises/stores`}),
    }),
    getOrganizerEventsOrganizersOrganizerIdEventsGet: build.query<
      GetOrganizerEventsOrganizersOrganizerIdEventsGetApiResponse,
      GetOrganizerEventsOrganizersOrganizerIdEventsGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/events`}),
    }),
    createEventOrganizersOrganizerIdEventsPost: build.mutation<
      CreateEventOrganizersOrganizerIdEventsPostApiResponse,
      CreateEventOrganizersOrganizerIdEventsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events`,
        method: "POST",
        body: queryArg.eventCreate,
      }),
    }),
    getEventOrganizersOrganizerIdEventsEventIdGet: build.query<
      GetEventOrganizersOrganizerIdEventsEventIdGetApiResponse,
      GetEventOrganizersOrganizerIdEventsEventIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}`,
      }),
    }),
    deleteEventOrganizersOrganizerIdEventsEventIdDelete: build.mutation<
      DeleteEventOrganizersOrganizerIdEventsEventIdDeleteApiResponse,
      DeleteEventOrganizersOrganizerIdEventsEventIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}`,
        method: "DELETE",
      }),
    }),
    updateEventOrganizersOrganizerIdEventsEventIdPatch: build.mutation<
      UpdateEventOrganizersOrganizerIdEventsEventIdPatchApiResponse,
      UpdateEventOrganizersOrganizerIdEventsEventIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}`,
        method: "PATCH",
        body: queryArg.eventUpdate,
      }),
    }),
    getEventWithIdEventsEventIdGet: build.query<
      GetEventWithIdEventsEventIdGetApiResponse,
      GetEventWithIdEventsEventIdGetApiArg
    >({
      query: (queryArg) => ({url: `/events/${queryArg.eventId}`}),
    }),
    exportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGet: build.query<
      ExportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGetApiResponse,
      ExportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/attendees/csv`,
      }),
    }),
    removeTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDelete:
      build.mutation<
        RemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteApiResponse,
        RemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/ticket_types/${queryArg.ticketTypeId}`,
          method: "DELETE",
        }),
      }),
    updateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPut: build.mutation<
      UpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutApiResponse,
      UpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/ticket_types/updateOrder`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    removeDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDelete:
      build.mutation<
        RemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteApiResponse,
        RemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/discounts/${queryArg.discountId}`,
          method: "DELETE",
        }),
      }),
    getEventImagesEventsEventIdImagesGet: build.query<
      GetEventImagesEventsEventIdImagesGetApiResponse,
      GetEventImagesEventsEventIdImagesGetApiArg
    >({
      query: (queryArg) => ({url: `/events/${queryArg.eventId}/images`}),
    }),
    uploadEventImageEventsEventIdImagesPost: build.mutation<
      UploadEventImageEventsEventIdImagesPostApiResponse,
      UploadEventImageEventsEventIdImagesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/events/${queryArg.eventId}/images`,
        method: "POST",
        body: queryArg.bodyUploadEventImageEventsEventIdImagesPost,
      }),
    }),
    removeEventImageEventsEventIdImagesImageIdDelete: build.mutation<
      RemoveEventImageEventsEventIdImagesImageIdDeleteApiResponse,
      RemoveEventImageEventsEventIdImagesImageIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/events/${queryArg.eventId}/images/${queryArg.imageId}`,
        method: "DELETE",
      }),
    }),
    createOrderOrdersPost: build.mutation<
      CreateOrderOrdersPostApiResponse,
      CreateOrderOrdersPostApiArg
    >({
      query: (queryArg) => ({url: `/orders`, method: "POST", body: queryArg.orderCreate}),
    }),
    calculateOrderPriceOrdersCalculationsPost: build.mutation<
      CalculateOrderPriceOrdersCalculationsPostApiResponse,
      CalculateOrderPriceOrdersCalculationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/orders/calculations`,
        method: "POST",
        body: queryArg.orderPriceCalculationRequest,
      }),
    }),
    getOrdersForUserUsersUserIdOrdersGet: build.query<
      GetOrdersForUserUsersUserIdOrdersGetApiResponse,
      GetOrdersForUserUsersUserIdOrdersGetApiArg
    >({
      query: (queryArg) => ({url: `/users/${queryArg.userId}/orders`}),
    }),
    getOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGet: build.query<
      GetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetApiResponse,
      GetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/orders`,
      }),
    }),
    getOrdersForOrganizerOrganizersOrganizerIdOrdersGet: build.query<
      GetOrdersForOrganizerOrganizersOrganizerIdOrdersGetApiResponse,
      GetOrdersForOrganizerOrganizersOrganizerIdOrdersGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/orders`}),
    }),
    updateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatch: build.mutation<
      UpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchApiResponse,
      UpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/${queryArg.eventId}/orders/${queryArg.orderId}`,
        method: "PATCH",
        body: queryArg.orderUpdate,
      }),
    }),
    getByIdOrdersOrderIdGet: build.query<
      GetByIdOrdersOrderIdGetApiResponse,
      GetByIdOrdersOrderIdGetApiArg
    >({
      query: (queryArg) => ({url: `/orders/${queryArg.orderId}`}),
    }),
    stripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPost: build.mutation<
      StripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPostApiResponse,
      StripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPostApiArg
    >({
      query: () => ({url: `/payments/stripe/webhooks/invoices`, method: "POST"}),
    }),
    stripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPost:
      build.mutation<
        StripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPostApiResponse,
        StripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPostApiArg
      >({
        query: () => ({url: `/payments/stripe/webhooks/register_billing_method`, method: "POST"}),
      }),
    stripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPost: build.mutation<
      StripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPostApiResponse,
      StripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/payments/stripe/webhooks/${queryArg.organizerId}`,
        method: "POST",
      }),
    }),
    ryftWebhookForOrganizersPaymentsRyftWebhooksPost: build.mutation<
      RyftWebhookForOrganizersPaymentsRyftWebhooksPostApiResponse,
      RyftWebhookForOrganizersPaymentsRyftWebhooksPostApiArg
    >({
      query: () => ({url: `/payments/ryft/webhooks`, method: "POST"}),
    }),
    getTransactionsOrganizersOrganizerIdTransactionsGet: build.query<
      GetTransactionsOrganizersOrganizerIdTransactionsGetApiResponse,
      GetTransactionsOrganizersOrganizerIdTransactionsGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/transactions`}),
    }),
    generateInvoicesInvoicesGenerateYearMonthPost: build.mutation<
      GenerateInvoicesInvoicesGenerateYearMonthPostApiResponse,
      GenerateInvoicesInvoicesGenerateYearMonthPostApiArg
    >({
      query: (queryArg) => ({
        url: `/invoices/generate/${queryArg.year}/${queryArg.month}`,
        method: "POST",
      }),
    }),
    getOrganizerInvoicesOrganizersOrganizerIdInvoicesGet: build.query<
      GetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetApiResponse,
      GetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetApiArg
    >({
      query: (queryArg) => ({url: `/organizers/${queryArg.organizerId}/invoices`}),
    }),
    getOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGet: build.query<
      GetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetApiResponse,
      GetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetApiArg
    >({
      query: (queryArg) => {
        const params = {};
        if (queryArg.states) {
          params["states"] = queryArg.states;
        }
        return {
          url: `/organizers/${queryArg.organizerId}/billing-methods`,
          params: params,
        };
      },
    }),
    createOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPost: build.mutation<
      CreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostApiResponse,
      CreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/billing-methods`,
        method: "POST",
        body: queryArg.billingMethodCreate,
      }),
    }),
    deleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDelete:
      build.mutation<
        DeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteApiResponse,
        DeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/organizers/${queryArg.organizerId}/billing-methods/${queryArg.billingMethodId}`,
          method: "DELETE",
        }),
      }),
    updateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatch:
      build.mutation<
        UpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchApiResponse,
        UpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/organizers/${queryArg.organizerId}/billing-methods/${queryArg.billingMethodId}/state/payment_submitted`,
          method: "PATCH",
        }),
      }),
    validateEventTicketOrganizersOrganizerIdEventsValidationsPost: build.mutation<
      ValidateEventTicketOrganizersOrganizerIdEventsValidationsPostApiResponse,
      ValidateEventTicketOrganizersOrganizerIdEventsValidationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/events/validations`,
        method: "POST",
        body: queryArg.validateEventTicket,
      }),
    }),
    getOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGet: build.query<
      GetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetApiResponse,
      GetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetApiArg
    >({
      query: (queryArg) => {
        const params = {};
        if (queryArg.type) {
          params["type"] = queryArg.type;
        }
        return {
          url: `/organizers/${queryArg.organizerId}/integrations`,
          params: params,
        };
      },
    }),
    createOrganizersIntegrationOrganizersOrganizerIdIntegrationsPost: build.mutation<
      CreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostApiResponse,
      CreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integrations`,
        method: "POST",
        body: queryArg.integrationCreate,
      }),
    }),
    authorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPost:
      build.mutation<
        AuthorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPostApiResponse,
        AuthorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPostApiArg
      >({
        query: (queryArg) => ({
          url: `/organizers/${queryArg.organizerId}/integrations/authorize/${queryArg.apiKey}`,
          method: "POST",
        }),
      }),
    deleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDelete: build.mutation<
      DeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteApiResponse,
      DeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integrations/${queryArg.integrationId}`,
        method: "DELETE",
      }),
    }),
    ordersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGet: build.query<
      OrdersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGetApiResponse,
      OrdersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integrations/orders/perform-list`,
      }),
    }),
    eventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGet: build.query<
      EventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGetApiResponse,
      EventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integrations/events/perform-list`,
      }),
    }),
    getOrganizersOrganizerIdInvitationsGet: build.query<
      GetOrganizersOrganizerIdInvitationsGetApiResponse,
      GetOrganizersOrganizerIdInvitationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/invitations`,
        params: {id: queryArg.id},
      }),
    }),
    createOrganizersOrganizerIdInvitationsPost: build.mutation<
      CreateOrganizersOrganizerIdInvitationsPostApiResponse,
      CreateOrganizersOrganizerIdInvitationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/invitations`,
        method: "POST",
        body: queryArg.invitationCreate,
      }),
    }),
    getOrganizersOrganizerIdIntegrationWebhooksEventNameGet: build.query<
      GetOrganizersOrganizerIdIntegrationWebhooksEventNameGetApiResponse,
      GetOrganizersOrganizerIdIntegrationWebhooksEventNameGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integration-webhooks/${queryArg.eventName}`,
      }),
    }),
    createOrganizersOrganizerIdIntegrationWebhooksPost: build.mutation<
      CreateOrganizersOrganizerIdIntegrationWebhooksPostApiResponse,
      CreateOrganizersOrganizerIdIntegrationWebhooksPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integration-webhooks`,
        method: "POST",
        body: queryArg.integrationWebhookCreate,
      }),
    }),
    deleteOrganizersOrganizerIdIntegrationWebhooksIdDelete: build.mutation<
      DeleteOrganizersOrganizerIdIntegrationWebhooksIdDeleteApiResponse,
      DeleteOrganizersOrganizerIdIntegrationWebhooksIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/organizers/${queryArg.organizerId}/integration-webhooks/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as beOrgApi};
export type RootGetApiResponse = /** status 200 Successful Response */ any;
export type RootGetApiArg = void;
export type HealthHealthGetApiResponse = /** status 200 Successful Response */ any;
export type HealthHealthGetApiArg = void;
export type GetUserUsersUserIdGetApiResponse = /** status 200 Successful Response */ UserRead;
export type GetUserUsersUserIdGetApiArg = {
  userId: string;
};
export type CreateUserUsersUserIdPutApiResponse = /** status 201 Successful Response */ UserRead;
export type CreateUserUsersUserIdPutApiArg = {
  userId: string;
  userCreate: UserCreate;
};
export type UpdateUserUsersUserIdPatchApiResponse = /** status 200 Successful Response */ UserRead;
export type UpdateUserUsersUserIdPatchApiArg = {
  userId: string;
  userUpdate: UserUpdate;
};
export type GetTeamMembersUsersUserIdTeamGetApiResponse =
  /** status 200 Successful Response */ UserRead[];
export type GetTeamMembersUsersUserIdTeamGetApiArg = {
  userId: string;
};
export type VerifyCaptchaUsersVerifyCaptchaPostApiResponse =
  /** status 200 Successful Response */ UserVerificationResponse;
export type VerifyCaptchaUsersVerifyCaptchaPostApiArg = {
  userVerificationRequest: UserVerificationRequest;
};
export type GetOrganizerUsersUserIdOrganizersOrganizerIdGetApiResponse =
  /** status 200 Successful Response */ OrganizerRead;
export type GetOrganizerUsersUserIdOrganizersOrganizerIdGetApiArg = {
  userId: string;
  organizerId: string;
};
export type UpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchApiResponse =
  /** status 200 Successful Response */ OrganizerRead;
export type UpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchApiArg = {
  userId: string;
  organizerId: string;
  organizerUpdate: OrganizerUpdate;
};
export type GetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostApiResponse =
  /** status 200 Successful Response */ OrganizerStatsRead;
export type GetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostApiArg = {
  userId: string;
  organizerId: string;
  organizerStatsPost: OrganizerStatsPost;
};
export type GetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetApiResponse =
  /** status 200 Successful Response */ AttendeeStats[];
export type GetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetApiArg = {
  userId: string;
  organizerId: string;
  eventFilter?: string[];
};
export type SendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostApiResponse =
  /** status 200 Successful Response */ any;
export type SendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostApiArg = {
  userId: string;
  organizerId: string;
  communityMessagePayload: CommunityMessagePayload;
};
export type SetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostApiResponse =
  /** status 200 Successful Response */ SetupRyftPaymentMethodResponse;
export type SetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostApiArg = {
  userId: string;
  organizerId: string;
  setupRyftPaymentMethodPayload: SetupRyftPaymentMethodPayload;
};
export type CompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostApiResponse =
  /** status 200 Successful Response */ any;
export type CompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostApiArg = {
  userId: string;
  organizerId: string;
  completeRyftPaymentMethodPayload: CompleteRyftPaymentMethodPayload;
};
export type GetAvailablePlansSubscriptionsPlansGetApiResponse =
  /** status 200 Successful Response */ SubscriptionsPlansRead;
export type GetAvailablePlansSubscriptionsPlansGetApiArg = void;
export type GetSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGetApiResponse =
  /** status 200 Successful Response */ SubscriptionRead;
export type GetSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGetApiArg = {
  organizerId: string;
  subscriptionId: string;
};
export type GetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetApiResponse =
  /** status 200 Successful Response */ SubscriptionsRead;
export type GetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetApiArg = {
  organizerId: string;
};
export type GetOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGetApiResponse =
  /** status 200 Successful Response */ MerchandiseStoreRead;
export type GetOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGetApiArg = {
  organizerId: string;
};
export type GetOrganizerEventsOrganizersOrganizerIdEventsGetApiResponse =
  /** status 200 Successful Response */ EventReadMultiple;
export type GetOrganizerEventsOrganizersOrganizerIdEventsGetApiArg = {
  organizerId: string;
};
export type CreateEventOrganizersOrganizerIdEventsPostApiResponse =
  /** status 200 Successful Response */ EventRead;
export type CreateEventOrganizersOrganizerIdEventsPostApiArg = {
  organizerId: string;
  eventCreate: EventCreate;
};
export type GetEventOrganizersOrganizerIdEventsEventIdGetApiResponse =
  /** status 200 Successful Response */ EventRead;
export type GetEventOrganizersOrganizerIdEventsEventIdGetApiArg = {
  organizerId: string;
  eventId: string;
};
export type DeleteEventOrganizersOrganizerIdEventsEventIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteEventOrganizersOrganizerIdEventsEventIdDeleteApiArg = {
  organizerId: string;
  eventId: string;
};
export type UpdateEventOrganizersOrganizerIdEventsEventIdPatchApiResponse =
  /** status 200 Successful Response */ EventRead;
export type UpdateEventOrganizersOrganizerIdEventsEventIdPatchApiArg = {
  organizerId: string;
  eventId: string;
  eventUpdate: EventUpdate;
};
export type GetEventWithIdEventsEventIdGetApiResponse =
  /** status 200 Successful Response */ EventPublicRead;
export type GetEventWithIdEventsEventIdGetApiArg = {
  eventId: string;
};
export type ExportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGetApiResponse =
  /** status 200 Successful Response */ undefined;
export type ExportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGetApiArg = {
  organizerId: string;
  eventId: string;
};
export type RemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type RemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteApiArg =
  {
    ticketTypeId: string;
    organizerId: string;
    eventId: string;
  };
export type UpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutApiResponse =
  /** status 204 Successful Response */ undefined;
export type UpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutApiArg = {
  organizerId: string;
  eventId: string;
  body: TicketTypeUpdateOrder[];
};
export type RemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type RemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteApiArg =
  {
    discountId: string;
    organizerId: string;
    eventId: string;
  };
export type GetEventImagesEventsEventIdImagesGetApiResponse =
  /** status 200 Successful Response */ EventImageReadMultiple;
export type GetEventImagesEventsEventIdImagesGetApiArg = {
  eventId: string;
};
export type UploadEventImageEventsEventIdImagesPostApiResponse =
  /** status 200 Successful Response */ EventImageRead;
export type UploadEventImageEventsEventIdImagesPostApiArg = {
  eventId: string;
  bodyUploadEventImageEventsEventIdImagesPost: BodyUploadEventImageEventsEventIdImagesPost;
};
export type RemoveEventImageEventsEventIdImagesImageIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type RemoveEventImageEventsEventIdImagesImageIdDeleteApiArg = {
  eventId: string;
  imageId: string;
};
export type CreateOrderOrdersPostApiResponse = /** status 200 Successful Response */ OrderRead;
export type CreateOrderOrdersPostApiArg = {
  orderCreate: OrderCreate;
};
export type CalculateOrderPriceOrdersCalculationsPostApiResponse =
  /** status 200 Successful Response */ OrderPriceCalculation;
export type CalculateOrderPriceOrdersCalculationsPostApiArg = {
  orderPriceCalculationRequest: OrderPriceCalculationRequest;
};
export type GetOrdersForUserUsersUserIdOrdersGetApiResponse =
  /** status 200 Successful Response */ OrderReadMultiple;
export type GetOrdersForUserUsersUserIdOrdersGetApiArg = {
  userId: string;
};
export type GetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetApiResponse =
  /** status 200 Successful Response */ OrderReadMultiple;
export type GetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetApiArg = {
  organizerId: string;
  eventId: string;
};
export type GetOrdersForOrganizerOrganizersOrganizerIdOrdersGetApiResponse =
  /** status 200 Successful Response */ OrderReadMultiple;
export type GetOrdersForOrganizerOrganizersOrganizerIdOrdersGetApiArg = {
  organizerId: string;
};
export type UpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchApiResponse =
  /** status 200 Successful Response */ OrderRead;
export type UpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchApiArg = {
  organizerId: string;
  eventId: string;
  orderId: string;
  orderUpdate: OrderUpdate;
};
export type GetByIdOrdersOrderIdGetApiResponse = /** status 200 Successful Response */ OrderRead;
export type GetByIdOrdersOrderIdGetApiArg = {
  orderId: string;
};
export type StripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPostApiResponse =
  /** status 200 Successful Response */ any;
export type StripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPostApiArg = void;
export type StripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPostApiResponse =
  /** status 200 Successful Response */ any;
export type StripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPostApiArg =
  void;
export type StripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPostApiResponse =
  /** status 200 Successful Response */ any;
export type StripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPostApiArg = {
  organizerId: string;
};
export type RyftWebhookForOrganizersPaymentsRyftWebhooksPostApiResponse =
  /** status 200 Successful Response */ any;
export type RyftWebhookForOrganizersPaymentsRyftWebhooksPostApiArg = void;
export type GetTransactionsOrganizersOrganizerIdTransactionsGetApiResponse =
  /** status 200 Successful Response */ TransactionReadMultiple;
export type GetTransactionsOrganizersOrganizerIdTransactionsGetApiArg = {
  organizerId: string;
};
export type GenerateInvoicesInvoicesGenerateYearMonthPostApiResponse =
  /** status 204 Successful Response */ undefined;
export type GenerateInvoicesInvoicesGenerateYearMonthPostApiArg = {
  year: number;
  month: number;
};
export type GetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetApiResponse =
  /** status 200 Successful Response */ InvoiceReadMultiple;
export type GetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetApiArg = {
  organizerId: string;
};
export type GetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetApiResponse =
  /** status 200 Successful Response */ BillingMethodReadMultiple;
export type GetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetApiArg = {
  organizerId: string;
  states?: BillingMethodState[];
};
export type CreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostApiResponse =
  /** status 200 Successful Response */ BillingMethodRead;
export type CreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostApiArg = {
  organizerId: string;
  billingMethodCreate: BillingMethodCreate;
};
export type DeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteApiArg =
  {
    billingMethodId: string;
    organizerId: string;
  };
export type UpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchApiResponse =
  /** status 200 Successful Response */ BillingMethodRead;
export type UpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchApiArg =
  {
    billingMethodId: string;
    organizerId: string;
  };
export type ValidateEventTicketOrganizersOrganizerIdEventsValidationsPostApiResponse =
  /** status 200 Successful Response */ TicketValidationResponse;
export type ValidateEventTicketOrganizersOrganizerIdEventsValidationsPostApiArg = {
  organizerId: string;
  validateEventTicket: ValidateEventTicket;
};
export type GetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetApiResponse =
  /** status 200 Successful Response */ IntegrationReadMultiple;
export type GetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetApiArg = {
  organizerId: string;
  type?: IntegrationType;
};
export type CreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostApiResponse =
  /** status 200 Successful Response */ IntegrationRead;
export type CreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostApiArg = {
  organizerId: string;
  integrationCreate: IntegrationCreate;
};
export type AuthorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPostApiResponse =
  /** status 200 Successful Response */ any;
export type AuthorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPostApiArg =
  {
    organizerId: string;
    apiKey: string;
  };
export type DeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteApiArg =
  {
    integrationId: string;
    organizerId: string;
  };
export type OrdersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGetApiResponse =
  /** status 200 Successful Response */ any;
export type OrdersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGetApiArg = {
  organizerId: string;
};
export type EventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGetApiResponse =
  /** status 200 Successful Response */ any;
export type EventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGetApiArg = {
  organizerId: string;
};
export type GetOrganizersOrganizerIdInvitationsGetApiResponse =
  /** status 200 Successful Response */ InvitationRead;
export type GetOrganizersOrganizerIdInvitationsGetApiArg = {
  organizerId: string;
  id: string;
};
export type CreateOrganizersOrganizerIdInvitationsPostApiResponse =
  /** status 200 Successful Response */ InvitationRead;
export type CreateOrganizersOrganizerIdInvitationsPostApiArg = {
  organizerId: string;
  invitationCreate: InvitationCreate;
};
export type GetOrganizersOrganizerIdIntegrationWebhooksEventNameGetApiResponse =
  /** status 200 Successful Response */ IntegrationWebhookRead;
export type GetOrganizersOrganizerIdIntegrationWebhooksEventNameGetApiArg = {
  organizerId: string;
  eventName: string;
};
export type CreateOrganizersOrganizerIdIntegrationWebhooksPostApiResponse =
  /** status 200 Successful Response */ IntegrationWebhookRead;
export type CreateOrganizersOrganizerIdIntegrationWebhooksPostApiArg = {
  organizerId: string;
  integrationWebhookCreate: IntegrationWebhookCreate;
};
export type DeleteOrganizersOrganizerIdIntegrationWebhooksIdDeleteApiResponse =
  /** status 204 Successful Response */ undefined;
export type DeleteOrganizersOrganizerIdIntegrationWebhooksIdDeleteApiArg = {
  id: string;
  organizerId: string;
};
export type OrganizerEventExpectedAudienceSizeOption = "0_1000" | "1000_10000" | "10000_";
export type OrganizerEventType = "concert" | "conference" | "sport" | "other";
export type OrganizerReadAddress = {
  business_name?: string;
  street?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country_code?: string;
  vat_id?: string;
};
export type OrganizerReadBillingDetails = {
  address: OrganizerReadAddress;
  outstanding_balance?: number;
  next_billing_on?: string;
};
export type OrganizerReadPaymentMethodStripe = {
  client_public_key?: string;
  client_secret?: string;
  is_active: boolean;
};
export type OrganizerReadPaymentMethodRyft = {
  is_active: boolean;
  email?: string;
};
export type OrganizerReadPaymentMethodWireTransfer = {
  bank_account_number?: string;
  is_active: boolean;
};
export type OrganizerReadPaymentMethods = {
  stripe: OrganizerReadPaymentMethodStripe;
  ryft: OrganizerReadPaymentMethodRyft;
  wire_transfer: OrganizerReadPaymentMethodWireTransfer;
};
export type OrganizerRead = {
  name?: string;
  bio?: string;
  support_email?: string;
  expected_event_audience_size?: OrganizerEventExpectedAudienceSizeOption;
  event_type?: OrganizerEventType;
  billing_details: OrganizerReadBillingDetails;
  payment_methods: OrganizerReadPaymentMethods;
  pass_on_fees: boolean;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type UserDiscoveredByOption = "social_media" | "friends" | "other";
export type UserRole = "owner" | "admin" | "member" | "attendee";
export type UserRead = {
  id: string;
  href?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  created_at: string;
  updated_at: string;
  organizer?: OrganizerRead;
  discovered_by?: UserDiscoveredByOption;
  team_owner_id?: string;
  role: UserRole;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type UserCreate = {
  email: string;
  first_name?: string;
  last_name?: string;
  team_owner_id?: string;
  role: UserRole;
};
export type UserUpdate = {
  first_name?: string;
  last_name?: string;
  discovered_by?: UserDiscoveredByOption;
};
export type UserVerificationResponse = {
  success: boolean;
};
export type UserVerificationRequest = {
  recaptchaResponse: string;
};
export type OrganizerUpdateBillingDetailsAddress = {
  business_name?: string;
  street?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country_code?: string;
  vat_id?: string;
};
export type OrganizerUpdateBillingDetails = {
  address?: OrganizerUpdateBillingDetailsAddress;
};
export type OrganizerBaseBillingMethodStripe = {
  client_public_key?: string;
  client_secret?: string;
  is_active: boolean;
};
export type OrganizerBaseBillingMethodRyft = {
  is_active: boolean;
  email?: string;
};
export type OrganizerBaseBillingMethodWireTransfer = {
  bank_account_number?: string;
  is_active: boolean;
};
export type OrganizerUpdateBillingMethods = {
  stripe?: OrganizerBaseBillingMethodStripe;
  ryft?: OrganizerBaseBillingMethodRyft;
  wire_transfer?: OrganizerBaseBillingMethodWireTransfer;
};
export type OrganizerUpdate = {
  name?: string;
  bio?: string;
  support_email?: string;
  expected_event_audience_size?: OrganizerEventExpectedAudienceSizeOption;
  event_type?: OrganizerEventType;
  billing_details?: OrganizerUpdateBillingDetails;
  payment_methods?: OrganizerUpdateBillingMethods;
  pass_on_fees?: boolean;
};
export type OrganizerStatsReadRevenue = {
  total: {
    [key: string]: number;
  };
  tickets: {
    [key: string]: number;
  };
  previous_period: {
    [key: string]: number;
  };
};
export type OrganizerStatsReadOrders = {
  total: number;
  previous_period: number;
  chart: {
    [key: string]: number;
  }[];
};
export type OrganizerStatsReadAttendees = {
  total: number;
  previous_period: number;
  chart: {
    [key: string]: number;
  }[];
};
export type OrganizerStatsRead = {
  revenue: OrganizerStatsReadRevenue;
  orders: OrganizerStatsReadOrders;
  attendees: OrganizerStatsReadAttendees;
};
export type OrganizerStatsPeriod = "day" | "week" | "month";
export type OrganizerStatsPost = {
  period: OrganizerStatsPeriod;
};
export type AttendeeStats = {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  events_attended: number;
};
export type CommunityMessagePayload = {
  emails: string[];
  subject: string;
  message: string;
};
export type SetupRyftPaymentMethodResponse = {
  organizer: OrganizerRead;
  register_url: string;
  authorize_url: string;
};
export type SetupRyftPaymentMethodPayload = {
  email_address: string;
  register_redirect: string;
  authorize_redirect: string;
  account_id?: string;
};
export type CompleteRyftPaymentMethodPayload = {
  subscription_plan_id: string;
  sub_account_id?: string;
};
export type PaymentGateway = "stripe" | "ryft";
export type SubscriptionPlanRead = {
  name: string;
  fee_percentage: number;
  rebate_percentage: number;
  payment_gateway: PaymentGateway;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type SubscriptionsPlansRead = {
  items: SubscriptionPlanRead[];
};
export type SubscriptionState = "active" | "paused" | "expired";
export type StripeCardDetails = {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
};
export type PaymentMethod = "stripe" | "wire_transfer" | "ryft";
export type PaymentState =
  | "waiting_for_payment"
  | "paid"
  | "expired"
  | "cancelled"
  | "refunded"
  | "requested";
export type PaymentDetailsRead = {
  stripe_payment_intent_id?: string;
  ryft_payment_secret?: string;
};
export type PaymentRead = {
  method?: PaymentMethod;
  state: PaymentState;
  amount: number;
  currency: string;
  details: PaymentDetailsRead;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type BillingMethodState = "active" | "pending_payment" | "pending_verification" | "inactive";
export type BillingMethodRead = {
  external_id: string;
  payment_gateway: PaymentGateway;
  is_default: boolean;
  card?: StripeCardDetails;
  payment?: PaymentRead;
  state: BillingMethodState;
  id: string;
  created_at: string;
  updated_at: string;
};
export type SubscriptionRead = {
  plan: SubscriptionPlanRead;
  state: SubscriptionState;
  expires_at?: string;
  billing_method: BillingMethodRead;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type SubscriptionsRead = {
  items: SubscriptionRead[];
};
export type MerchandiseStoreReadPaymentOptions = {
  methods: PaymentMethod[];
  vat_enabled: boolean;
  collect_billing_details: boolean;
};
export type MerchandiseStoreRead = {
  digital_merchandise?: string;
  payment_options: MerchandiseStoreReadPaymentOptions;
  discounts?: string;
  name?: string;
  description_html?: string;
};
export type EventState = "draft" | "published";
export type EventType = "physical" | "virtual" | "hybrid";
export type CustomAttendeeFieldType = "free_text" | "number" | "dropdown" | "checkbox";
export type CustomAttendeeFieldConfigRead = {
  type: CustomAttendeeFieldType;
  name: string;
  slug: string;
  enum_options?: string[];
  description?: string;
  isRequired: boolean;
};
export type LocationRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name: string;
  lat?: number;
  lon?: number;
  street: string;
  city: string;
  state: string;
  country_code: string;
  postal_code: string;
  timezone_info: string;
};
export type DigitalMerchandiseReadNftStyle = {
  style_id?: string;
};
export type DigitalMerchandiseRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name?: string;
  price: number;
  maximum_quantity?: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: DigitalMerchandiseReadNftStyle;
};
export type DiscountType = "order_discount" | "volume_discount" | "type_discount";
export type DiscountRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  type: DiscountType;
  name: string;
  percentage: number;
  coupon_code?: string;
  start_datetime: string;
  end_datetime?: string;
  ticket_type_id?: string;
  minimum_order_quantity?: number;
  is_exclusive_discount: boolean;
};
export type EventPaymentOptionsRead = {
  methods?: PaymentMethod[];
  vat_rate?: number;
  collect_billing_details?: boolean;
  allow_ticket_cancellation?: boolean;
};
export type EventImageRead = {
  is_featured: boolean;
  url: string;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type EventOrganizerDetailsRead = {
  id: string;
  name?: string;
  support_email?: string;
  stripe_client_public_key?: string;
  ryft_sub_account_id?: string;
  pass_on_fees?: boolean;
};
export type EventStatsRead = {
  tickets_sold_count: number;
  merch_sold_count: number;
  total_revenue: number;
  total_orders_count: number;
  tickets_total_count: number;
};
export type TicketTypeNftStyleRead = {
  style_id?: string;
};
export type TicketTypeReadStatistics = {
  tickets_sold: number;
  total_revenue: number;
  tickets_total_count: number;
};
export type TicketTypeStatus = "published" | "unpublished";
export type TicketTypeRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name: string;
  price: number;
  maximum_quantity: number;
  minimum_order_quantity: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: TicketTypeNftStyleRead;
  tickets_available?: number;
  order: number;
  statistics: TicketTypeReadStatistics;
  state: TicketTypeStatus;
};
export type EventRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name?: string;
  state: EventState;
  start_datetime?: string;
  end_datetime?: string;
  type?: EventType;
  virtual_event_url?: string;
  description_html?: string;
  require_email_before_checkout?: boolean;
  require_attendee_name_before_checkout?: boolean;
  collect_attendee_name?: boolean;
  allow_attendee_name_update?: boolean;
  currency: string;
  custom_attendee_fields: CustomAttendeeFieldConfigRead[];
  location?: LocationRead;
  digital_merchandises?: DigitalMerchandiseRead[];
  discounts?: DiscountRead[];
  payment_options?: EventPaymentOptionsRead;
  timezone_info: string;
  use_24_hour_time_format: boolean;
  registration_start_datetime: string;
  registration_end_datetime?: string;
  images: EventImageRead[];
  organizer: EventOrganizerDetailsRead;
  email_note?: string;
  terms_and_conditions_url?: string;
  statistics: EventStatsRead;
  ticket_types?: TicketTypeRead[];
};
export type EventReadMultiple = {
  items: EventRead[];
};
export type CustomAttendeeFieldConfigCreate = {
  type: CustomAttendeeFieldType;
  name: string;
  enum_options?: string[];
  description?: string;
  isRequired: boolean;
};
export type LocationCreate = {
  name: string;
  lat: number;
  lon: number;
  street: string;
  city: string;
  state: string;
  country_code: string;
  postal_code: string;
  timezone_info: string;
};
export type TicketTypeNftStyleUpdate = {
  style_id?: string;
};
export type TicketTypeCreate = {
  name: string;
  price: number;
  maximum_quantity: number;
  minimum_order_quantity: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: TicketTypeNftStyleUpdate;
  order: number;
};
export type DigitalMerchandiseNftStyleCreate = {
  style_id?: string;
};
export type DigitalMerchandiseCreate = {
  name: string;
  price: number;
  maximum_quantity: number;
  minimum_order_quantity: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: DigitalMerchandiseNftStyleCreate;
};
export type DiscountCreate = {
  type: DiscountType;
  name: string;
  percentage: number;
  coupon_code?: string;
  start_datetime: string;
  end_datetime?: string;
  ticket_type_id?: string;
  minimum_order_quantity?: number;
  is_exclusive_discount: boolean;
};
export type EventPaymentOptionsCreate = {
  methods?: PaymentMethod[];
  vat_rate?: number;
  collect_billing_details?: boolean;
  allow_ticket_cancellation?: boolean;
};
export type EventCreate = {
  name: string;
  start_datetime: string;
  end_datetime?: string;
  type: EventType;
  virtual_event_url?: string;
  description_html?: string;
  require_email_before_checkout: boolean;
  require_attendee_name_before_checkout: boolean;
  collect_attendee_name: boolean;
  allow_attendee_name_update: boolean;
  currency: string;
  custom_attendee_fields?: CustomAttendeeFieldConfigCreate[];
  location?: LocationCreate;
  ticket_types?: TicketTypeCreate[];
  digital_merchandises?: DigitalMerchandiseCreate[];
  discounts?: DiscountCreate[];
  payment_options?: EventPaymentOptionsCreate;
  timezone_info: string;
  use_24_hour_time_format: boolean;
  registration_start_datetime: string;
  registration_end_datetime?: string;
  email_note?: string;
  terms_and_conditions_url?: string;
};
export type LocationUpdate = {
  name?: string;
  lat?: number;
  lon?: number;
  street?: string;
  city?: string;
  state?: string;
  country_code?: string;
  postal_code?: string;
  timezone_info?: string;
  id: string;
};
export type TicketTypeUpdate = {
  id: string;
  name?: string;
  price?: number;
  maximum_quantity?: number;
  minimum_order_quantity?: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: TicketTypeNftStyleUpdate;
  order?: number;
};
export type DigitalMerchandiseUpdate = {
  id: string;
  name?: string;
  price?: number;
  maximum_quantity?: number;
  minimum_order_quantity?: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: DigitalMerchandiseNftStyleCreate;
};
export type DiscountUpdate = {
  id: string;
  type?: DiscountType;
  name?: string;
  percentage?: number;
  coupon_code?: string;
  start_datetime?: string;
  end_datetime?: string;
  ticket_type_id?: string;
  minimum_order_quantity?: number;
  is_exclusive_discount?: boolean;
};
export type EventUpdate = {
  state?: EventState;
  name?: string;
  start_datetime?: string;
  end_datetime?: string;
  type?: EventType;
  virtual_event_url?: string;
  description_html?: string;
  require_email_before_checkout?: boolean;
  require_attendee_name_before_checkout?: boolean;
  collect_attendee_name?: boolean;
  allow_attendee_name_update?: boolean;
  currency?: string;
  custom_attendee_fields?: CustomAttendeeFieldConfigCreate[];
  location?: LocationCreate | LocationUpdate;
  ticket_types?: (TicketTypeUpdate | TicketTypeCreate)[];
  digital_merchandises?: (DigitalMerchandiseUpdate | DigitalMerchandiseCreate)[];
  discounts?: (DiscountUpdate | DiscountCreate)[];
  payment_options?: EventPaymentOptionsCreate;
  timezone_info?: string;
  use_24_hour_time_format?: boolean;
  registration_start_datetime?: string;
  registration_end_datetime?: string;
  email_note?: string;
  terms_and_conditions_url?: string;
};
export type TicketTypePublicRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name: string;
  price: number;
  maximum_quantity: number;
  minimum_order_quantity: number;
  maximum_order_quantity?: number;
  description?: string;
  nft?: TicketTypeNftStyleRead;
  tickets_available?: number;
  order: number;
};
export type EventPublicRead = {
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  name?: string;
  state: EventState;
  start_datetime?: string;
  end_datetime?: string;
  type?: EventType;
  virtual_event_url?: string;
  description_html?: string;
  require_email_before_checkout?: boolean;
  require_attendee_name_before_checkout?: boolean;
  collect_attendee_name?: boolean;
  allow_attendee_name_update?: boolean;
  currency: string;
  custom_attendee_fields: CustomAttendeeFieldConfigRead[];
  location?: LocationRead;
  digital_merchandises?: DigitalMerchandiseRead[];
  discounts?: DiscountRead[];
  payment_options?: EventPaymentOptionsRead;
  timezone_info: string;
  use_24_hour_time_format: boolean;
  registration_start_datetime: string;
  registration_end_datetime?: string;
  images: EventImageRead[];
  organizer: EventOrganizerDetailsRead;
  email_note?: string;
  terms_and_conditions_url?: string;
  ticket_types?: TicketTypePublicRead[];
};
export type TicketTypeUpdateOrder = {
  id: string;
  order?: number;
};
export type EventImageReadMultiple = {
  items: EventImageRead[];
};
export type BodyUploadEventImageEventsEventIdImagesPost = {
  file: Blob;
  is_featured?: boolean;
};
export type OrderBillingDetailsRead = {
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  street?: string;
  city?: string;
  billing_state?: string;
  zip?: string;
  country?: string;
  vat_number?: string;
};
export type OrderItemType = "ticket" | "digital_merchandise";
export type CustomAttendeeField = {
  value: string | string[];
  slug: string;
};
export type OrderTicketItemDetailsAttendeeRead = {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  attendee_fields?: CustomAttendeeField[];
};
export type OrderTicketItemDetailsNftStyleRead = {
  id: string;
  name: string;
  asset_type: string;
  asset_href: string;
};
export type OrderTicketItemDetailsNftRead = {
  id: string;
  href: string;
  street: string;
  ipfs_url: string;
  style: OrderTicketItemDetailsNftStyleRead;
};
export type OrderTicketItemDetailsRead = {
  id: string;
  href: string;
  name: string;
  ticket_type_id: string;
  purchased_price: number;
  currency: string;
  attendee: OrderTicketItemDetailsAttendeeRead;
  nft?: OrderTicketItemDetailsNftRead;
};
export type OrderTicketItemRead = {
  type: OrderItemType;
  details: OrderTicketItemDetailsRead;
};
export type OrderDiscountRead = {
  reference_discount_href: string;
  type: DiscountType;
  name: string;
  percentage: number;
  discounted_amount: number;
};
export type OrderReadEventDetails = {
  id: string;
  name: string;
};
export type OrderRead = {
  readable_id: string;
  event_id: string;
  billing_details: OrderBillingDetailsRead;
  refundable_booking_enabled: boolean;
  payment_method?: PaymentMethod;
  full_price: number;
  price: number;
  currency: string;
  state: PaymentState;
  nonce: string;
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
  items: OrderTicketItemRead[];
  applied_discounts: OrderDiscountRead[];
  payment?: PaymentRead;
  event: OrderReadEventDetails;
};
export type OrderBillingDetailsCreate = {
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  street?: string;
  city?: string;
  billing_state?: string;
  zip?: string;
  country?: string;
  vat_number?: string;
};
export type OrderTicketItemAttendeeCreate = {
  first_name?: string;
  last_name?: string;
  email: string;
  attendee_fields?: CustomAttendeeField[];
};
export type OrderTicketItemCreate = {
  type: OrderItemType;
  type_id: string;
  attendee?: OrderTicketItemAttendeeCreate;
  quantity: number;
};
export type OrderCreate = {
  event_id: string;
  user_id?: string;
  billing_details: OrderBillingDetailsCreate;
  coupon_codes?: string[];
  refundable_booking_enabled: boolean;
  payment_method?: PaymentMethod;
  nonce: string;
  items: OrderTicketItemCreate[];
};
export type OrderPriceCalculationItemDiscount = {
  discount: DiscountRead;
  discounted_amount: number;
};
export type OrderPriceCalculationItem = {
  name: string;
  price: number;
  price_before_discount: number;
  item_discounts: OrderPriceCalculationItemDiscount[];
};
export type OrderPriceCalculation = {
  items: OrderPriceCalculationItem[];
  order_discounts: OrderPriceCalculationItemDiscount[];
  total_price: number;
  platform_fee?: number;
  vat_rate?: number;
  vat_amount?: number;
};
export type OrderPriceCalculationRequest = {
  event_id: string;
  user_id?: string;
  items: OrderTicketItemCreate[];
  coupon_codes?: string[];
};
export type OrderReadMultiple = {
  items: OrderRead[];
};
export type OrderUpdateState = "paid" | "cancelled";
export type OrderUpdate = {
  state: OrderUpdateState;
};
export type TransactionType = "order_fee";
export type TransactionRead = {
  order_id: string;
  type: TransactionType;
  amount: number;
  currency: string;
  payment_gateway_fee: number;
  rebated_amount: number;
  subscription_plan_id: string;
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
};
export type TransactionReadMultiple = {
  items: TransactionRead[];
};
export type InvoiceState = "paid" | "unpaid" | "not_issued" | "voided";
export type InvoiceRead = {
  period_start_date: string;
  period_end_date: string;
  state: InvoiceState;
  amount: number;
  currency: string;
  pdf_url?: string;
  readable_id: string;
  payment: PaymentRead;
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
};
export type InvoiceReadMultiple = {
  items: InvoiceRead[];
};
export type BillingMethodReadMultiple = {
  items: BillingMethodRead[];
};
export type BillingMethodCreate = {
  payment_gateway: PaymentGateway;
  is_default?: boolean;
};
export type AttendeeRead = {
  first_name?: string;
  last_name?: string;
  email?: string;
  event_id: string;
  user_id?: string;
  attendee_fields?: CustomAttendeeField[];
  readable_id: string;
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
};
export type TicketState = "reserved" | "issued" | "cancelled" | "expired" | "used";
export type TicketBarcodeFormat = "qr";
export type TicketReadEventDetails = {
  id: string;
  href: string;
  name: string;
  images: EventImageRead[];
  currency: string;
};
export type TicketReadOrderDetails = {
  readable_id: string;
};
export type TicketReadOrganizerDetails = {
  id: string;
  href?: string;
  created_at: string;
  updated_at: string;
  name?: string;
  bio?: string;
  support_email?: string;
};
export type TicketRead = {
  attendee: AttendeeRead;
  server_data: string;
  ticket_type: TicketTypePublicRead;
  purchased_price: number;
  state: TicketState;
  expiration_date?: string;
  barcode_format: TicketBarcodeFormat;
  event: TicketReadEventDetails;
  order: TicketReadOrderDetails;
  organizer: TicketReadOrganizerDetails;
  used_at?: string;
  readable_id: string;
  id: string;
  href: string;
  created_at: string;
  updated_at: string;
};
export type TicketValidationResponse = {
  ticket: TicketRead;
  error?: string;
};
export type ValidateEventTicket = {
  ticket_data: string;
  source: string;
};
export type IntegrationType = "mail_chimp" | "zapier";
export type IntegrationRead = {
  api_key: string;
  type: IntegrationType;
  server_prefix?: string;
  audience_id?: string;
  id: string;
  created_at: string;
  updated_at: string;
};
export type IntegrationReadMultiple = {
  items: IntegrationRead[];
};
export type IntegrationCreate = {
  api_key?: string;
  type: IntegrationType;
  server_prefix?: string;
  audience_id?: string;
};
export type InvitationRead = {
  organizer_id: string;
  role: UserRole;
  email: string;
  id: string;
  created_at: string;
  updated_at: string;
};
export type InvitationCreate = {
  role: UserRole;
  email: string;
};
export type IntegrationWebhookRead = {
  targetUrl: string;
  event_name?: string;
  id: string;
  created_at: string;
  updated_at: string;
};
export type IntegrationWebhookCreate = {
  targetUrl: string;
  event_name: string;
  api_key: string;
};
export const {
  useRootGetQuery,
  useHealthHealthGetQuery,
  useGetUserUsersUserIdGetQuery,
  useCreateUserUsersUserIdPutMutation,
  useUpdateUserUsersUserIdPatchMutation,
  useGetTeamMembersUsersUserIdTeamGetQuery,
  useVerifyCaptchaUsersVerifyCaptchaPostMutation,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdGetQuery,
  useUpdateOrganizerUsersUserIdOrganizersOrganizerIdPatchMutation,
  useGetOrganizerUsersUserIdOrganizersOrganizerIdStatsPostMutation,
  useGetCommunityByOrganizerUsersUserIdOrganizersOrganizerIdAttendeesGetQuery,
  useSendCommunityEmailUsersUserIdOrganizersOrganizerIdCommunityEmailPostMutation,
  useSetupRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftSetupPostMutation,
  useCompleteRyftPaymentMethodUsersUserIdOrganizersOrganizerIdRyftCompletePostMutation,
  useGetAvailablePlansSubscriptionsPlansGetQuery,
  useGetSubscriptionOrganizersOrganizerIdSubscriptionsSubscriptionIdGetQuery,
  useGetOrganizersSubscriptionsOrganizersOrganizerIdSubscriptionsGetQuery,
  useGetOrganizersMerchandiseStoresOrganizersOrganizerIdMerchandisesStoresGetQuery,
  useGetOrganizerEventsOrganizersOrganizerIdEventsGetQuery,
  useCreateEventOrganizersOrganizerIdEventsPostMutation,
  useGetEventOrganizersOrganizerIdEventsEventIdGetQuery,
  useDeleteEventOrganizersOrganizerIdEventsEventIdDeleteMutation,
  useUpdateEventOrganizersOrganizerIdEventsEventIdPatchMutation,
  useGetEventWithIdEventsEventIdGetQuery,
  useExportAttendeesToCsvOrganizersOrganizerIdEventsEventIdAttendeesCsvGetQuery,
  useRemoveTicketTypeFromEventOrganizersOrganizerIdEventsEventIdTicketTypesTicketTypeIdDeleteMutation,
  useUpdateTicketOrderOrganizersOrganizerIdEventsEventIdTicketTypesUpdateOrderPutMutation,
  useRemoveDiscountFromEventOrganizersOrganizerIdEventsEventIdDiscountsDiscountIdDeleteMutation,
  useGetEventImagesEventsEventIdImagesGetQuery,
  useUploadEventImageEventsEventIdImagesPostMutation,
  useRemoveEventImageEventsEventIdImagesImageIdDeleteMutation,
  useCreateOrderOrdersPostMutation,
  useCalculateOrderPriceOrdersCalculationsPostMutation,
  useGetOrdersForUserUsersUserIdOrdersGetQuery,
  useGetOrdersForEventOrganizersOrganizerIdEventsEventIdOrdersGetQuery,
  useGetOrdersForOrganizerOrganizersOrganizerIdOrdersGetQuery,
  useUpdateOrderOrganizersOrganizerIdEventsEventIdOrdersOrderIdPatchMutation,
  useGetByIdOrdersOrderIdGetQuery,
  useStripeWebhookForInternalInvoicesPaymentsStripeWebhooksInvoicesPostMutation,
  useStripeWebhookForRegisterBillingMethodPaymentsStripeWebhooksRegisterBillingMethodPostMutation,
  useStripeWebhookForOrganizersPaymentsStripeWebhooksOrganizerIdPostMutation,
  useRyftWebhookForOrganizersPaymentsRyftWebhooksPostMutation,
  useGetTransactionsOrganizersOrganizerIdTransactionsGetQuery,
  useGenerateInvoicesInvoicesGenerateYearMonthPostMutation,
  useGetOrganizerInvoicesOrganizersOrganizerIdInvoicesGetQuery,
  useGetOrganizersBillingMethodsOrganizersOrganizerIdBillingMethodsGetQuery,
  useCreateOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsPostMutation,
  useDeleteOrganizersBillingMethodOrganizersOrganizerIdBillingMethodsBillingMethodIdDeleteMutation,
  useUpdateBillingMethodStateAfterPaymentWasSubmittedOrganizersOrganizerIdBillingMethodsBillingMethodIdStatePaymentSubmittedPatchMutation,
  useValidateEventTicketOrganizersOrganizerIdEventsValidationsPostMutation,
  useGetOrganizersIntegrationsOrganizersOrganizerIdIntegrationsGetQuery,
  useCreateOrganizersIntegrationOrganizersOrganizerIdIntegrationsPostMutation,
  useAuthorizeZapierOrganizersIntegrationOrganizersOrganizerIdIntegrationsAuthorizeApiKeyPostMutation,
  useDeleteOrganizersIntegrationOrganizersOrganizerIdIntegrationsIntegrationIdDeleteMutation,
  useOrdersPerformListOrganizersOrganizerIdIntegrationsOrdersPerformListGetQuery,
  useEventsPerformListOrganizersOrganizerIdIntegrationsEventsPerformListGetQuery,
  useGetOrganizersOrganizerIdInvitationsGetQuery,
  useCreateOrganizersOrganizerIdInvitationsPostMutation,
  useGetOrganizersOrganizerIdIntegrationWebhooksEventNameGetQuery,
  useCreateOrganizersOrganizerIdIntegrationWebhooksPostMutation,
  useDeleteOrganizersOrganizerIdIntegrationWebhooksIdDeleteMutation,
} = injectedRtkApi;
