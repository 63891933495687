import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconArrow: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 14"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="m1 13 6-6-6-6"
      stroke="#141A4E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconArrow;
