export const ORGANISER_ROLE_ORG_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/py3/api/user/role/org/`;
export const ORGANISER_APP = process.env.NEXT_PUBLIC_API_URL;
export const COOKIES_USER_ID = `uid`;
export const COOKIES_LAST_PAGE_URL = `lastUrl`;
export const COOKIES_EVENTS_BUFFER = `eventsBuffer`;

export const URL_QUERY_EMBED = "embed";

const ORGANISER_EVENTS_ROUTE = "/events/";

export const getOrganiserAppEventsUrl = () => {
  if (!window) {
    return ORGANISER_EVENTS_ROUTE;
  }

  return window.location.host.includes("localhost")
    ? `${ORGANISER_APP}${ORGANISER_EVENTS_ROUTE}`
    : ORGANISER_EVENTS_ROUTE;
};
