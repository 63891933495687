import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconIOnline: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 24"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith || "none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.377 16.45a.376.376 0 1 1-.377-.375h.003a.375.375 0 0 1 .375.376l-.001-.001zM2.547 1.75h20.907a.797.797 0 0 1 .797.796v12.055h-.89V3.015a.376.376 0 0 0-.376-.376H3.016a.376.376 0 0 0-.375.376v11.586h-.89V2.548a.796.796 0 0 1 .796-.798zM22.61 14.6V3.392H3.39v11.21H22.61zm.844 2.947a.798.798 0 0 0 .797-.799V15.35h-22.5v1.398a.798.798 0 0 0 .796.799h20.907zm-7.941 2.764h-5.024a7.807 7.807 0 0 0 1.35-2.015h2.323a7.804 7.804 0 0 0 1.35 2.015zm1.566 1.313v.562H8.923v-.562c0-.31.252-.561.562-.561h7.031c.31 0 .562.251.563.561zM2.547 18.296H11a5.938 5.938 0 0 1-1.64 2.022h-.003a1.314 1.314 0 0 0-1.184 1.306v.938c.001.206.169.373.375.373h8.905a.375.375 0 0 0 .375-.373v-.938a1.315 1.315 0 0 0-1.21-1.31.49.49 0 0 0-.067-.068 6.042 6.042 0 0 1-1.55-1.95h8.452A1.55 1.55 0 0 0 25 16.748V2.547A1.547 1.547 0 0 0 23.453 1H2.547A1.547 1.547 0 0 0 1 2.547v14.201a1.55 1.55 0 0 0 1.547 1.548z"
      fill="#C2C2C2"
      stroke="#C2C2C2"
      strokeWidth=".2"
    />
  </Icon>
);

export default IconIOnline;
