import * as React from "react";

import {Icon, IconProps} from "@chakra-ui/react";

const IconMaximize: React.FC<
  IconProps & {fillWith?: IconProps["fill"]; hoverColor?: IconProps["fill"]}
> = ({fillWith, hoverColor, _hover, ...props}) => (
  <Icon
    stroke="#A6A6A6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    transition="all 0.4s"
    _hover={hoverColor ? {fill: hoverColor, ...(_hover && _hover)} : _hover}
    fill={fillWith}
    {...props}
  >
    <path
      d="M9.375 1.875h3.75v3.75m-7.5 7.5h-3.75v-3.75m11.25-7.5L8.75 6.25m-6.875 6.875L6.25 8.75"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconMaximize;
